/* eslint-disable arrow-body-style */
/* eslint-disable react/require-default-props */
import {
  Autocomplete as MuiAutocomplete,
  FilterOptionsState,
  Value,
} from '@material-ui/lab';
import { useCallback } from 'react';
import _, {
  isEqual, findIndex, isArray, map, reduce,
} from 'lodash';
import {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Checkbox, CheckboxProps, CircularProgress } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

import { getSelectorValue } from '../../utils';
import { AutocompleteOption, AutocompleteProps } from './types';
import useStyles from './styles';
import { TextFieldInput } from '..';
import { DropdownOption } from '../../types';

const AutocompleteInput = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
    options,
    keySelector,
    labelSelector,
    emptyValue = '',
    disabled,
    name,
    label,
    required,
    withSelectAll = false,
    placeholder = 'Select',
    value,
    defaultValue,
    onSelectAll,
    withCheckBox = false,
    onChange,
    startAdornment,
    TextFieldProps,
    ...MuiAutocompleteProps
  }: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
    onSelectAll?:(isAllSelected: boolean, items: any[]) => void;
    withCheckBox?: boolean;
    withSelectAll?: boolean;
  }) => {
  const classes = useStyles();

  const isSelectAllOption = (
    option: any,
  ): option is AutocompleteOption => option && option.label && option.value;

  const isAllSelected = _.isArray(value) && value.length === options?.length;

  const handleOnChange = (
    e: React.ChangeEvent<{}>,
    newValue: Value<T, Multiple, boolean | DisableClearable, FreeSolo>,
  ) => {
    let v = emptyValue;
    if (isArray(newValue)) {
      const isSelectAll = _.some(
        newValue as DropdownOption[],
        (opt) => opt.value === 'SELECT_ALL',
      );

      if (isSelectAll) {
        const ids = _.uniq(
          _.map(
            options as any[] as DropdownOption[],
            (opt) => opt.value,
          ),
        );
        onSelectAll?.(!isAllSelected, ids);
        return;
      }
      v = map(newValue, (item: T) => getSelectorValue<T>(item, keySelector));
    } else if (newValue) {
      // TODO: replace any type with more precise type
      v = getSelectorValue<any>(newValue, keySelector);
    }
    onChange?.(e, v as any, 'select-option'); // Temporary fix for multiple type (as any)
  };

  const findItem = useCallback(
    (identifier: any) => {
      if (options && options.length > 0) {
        const index = findIndex(options, (o: T) => isEqual(
          getSelectorValue<T>(o, keySelector), identifier,
        ));
        if (index > -1) {
          return options[index];
        }
      }
      return null;
    },
    [options, keySelector],
  );

  const findValue = useCallback(
    (_value): any => {
      if (MuiAutocompleteProps.multiple) {
        return reduce(
          _value,
          (result: any[], identifier) => {
            const item = findItem(identifier);
            return item ? result.concat(item) : result;
          },
          [],
        );
      }
      return findItem(_value as any) ?? emptyValue; // Temporary fix for multiple type (as any)
    },
    [findItem, MuiAutocompleteProps.multiple, emptyValue],
  );

  const realValue = findValue(value ?? defaultValue);

  const defaultGetOptionLabel = (option: T) => (option ? getSelectorValue<T>(option, labelSelector) : '');

  const filter = createFilterOptions<T>();

  const defaultGetOptionSelected = (option: T, selected: T) => isEqual(
    getSelectorValue<T>(option, keySelector),
    getSelectorValue<T>(selected, keySelector),
  );

  const getOptions = () => {
    let cOptions = realValue === '' ? [realValue, ...(options || [])] : (options || []);
    cOptions = MuiAutocompleteProps.multiple && withSelectAll
      ? [{ label: 'Select All', value: 'SELECT_ALL' }, ...cOptions]
      : cOptions;
    return cOptions;
  };

  return (
    <MuiAutocomplete
      disabled={disabled}
      options={getOptions()}
      filterSelectedOptions={!withSelectAll}
      renderInput={(params) => (
        <TextFieldInput
          {...params}
          label={label}
          required={required}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {MuiAutocompleteProps.loading && <CircularProgress size={14} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          {...TextFieldProps}
        />
      )}
      value={realValue}
      noOptionsText="No options available"
      onChange={handleOnChange}
      renderOption={(option: T | AutocompleteOption, { selected }) => {
        let formControlLabel = defaultGetOptionLabel(option as T);
        if (withCheckBox) {
          const selectAllProps: Partial<CheckboxProps> = {};
          if (isSelectAllOption(option) && option.value === 'SELECT_ALL') {
            formControlLabel = option.label;
            selectAllProps.checked = isAllSelected;
          }
          return (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
                {...selectAllProps}
              />
              {formControlLabel}
            </>
          );
        }
        return (
          MuiAutocompleteProps.renderOption
            ? MuiAutocompleteProps.renderOption
            : defaultGetOptionLabel(option as T)
        );
      }}
      filterOptions={(_options: T[], state: FilterOptionsState<T>) => filter(_options, state)}
      getOptionLabel={defaultGetOptionLabel}
      getOptionSelected={defaultGetOptionSelected}
      disableClearable={
        !realValue || MuiAutocompleteProps.loading || MuiAutocompleteProps.disableClearable
      }
      ChipProps={{
        className: classes.chip,
        classes: {
          deleteIcon: classes.chipDeletable,
          label: classes.chipLabel,
        },
      }}
      classes={{
        root: classes.root,
        listbox: classes.listbox,
        option: classes.option,
        paper: classes.paper,
        groupLabel: classes.groupLabel,
        groupUl: classes.groupUl,
        endAdornment: classes.endAdornment,
        ...MuiAutocompleteProps.classes,
      }}
      {...MuiAutocompleteProps}
    />
  );
};

export default AutocompleteInput;
