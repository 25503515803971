export const leadDefaultWorkflowID = 'default-lead-workflow';

export const leadWorkflowTypeOptions = [
  {
    label: 'Notification',
    value: 'lead_notification',
  },
  {
    label: 'Automation',
    value: 'lead_automation',
  },
];

export const leadDefaultWorkflowEmailAssignee = {
  createdAt: '',
  createdByAffiliate: { id: 'system', name: 'System' },
  updatedAt: '',
  description: 'Send an email notification to assignee when lead or opportunity is assigned',
  enabled: true,
  id: leadDefaultWorkflowID,
  name: 'Email assignee when lead/opportunity is assigned',
  progressCalculationMethod: 'by_stage',
  startMethod: 'immediately',
  triggerFrequency: '* * * *',
  workflowAutomations: [{
    createdAt: '',
    updatedAt: '',
    enabled: true,
    id: 'default-lead-automation',
    name: '',
    workflowID: leadDefaultWorkflowID,
    workflowTypeID: 'lead_notification',
  }],
  workflowTypeID: 'lead_notification',
};

export const leadDefaultAutomationEmailAssignee = {
  enabled: true,
  id: 'default-lead-automation',
  name: '',
  workflowID: leadDefaultWorkflowID,
  workflowTypeID: 'lead_notification',
  rulesetLinking: 'AND',
  actions: [
    {
      id: 'action-id-1',
      sendEmailOn: '* * * * *',
      sendEmailRecipients: 'Assignee',
      sendEmailSubject: 'You have been assigned a new lead',
      sendEmailTemplate: '<p>Hi {{if .assignee_name}}{{.assignee_name}}{{else}}n/a{{end}},</p><p>A new lead has been assigned to you.</p>&zwnj;<p>Name: {{if .name}}{{.name}}{{else}}n/a{{end}}</p><p>Project name: {{if .project_names}}{{range $i, $project_name := .project_names}}{{if $i}}, {{end}}{{$project_name}}{{end}}{{else}}n/a{{end}}</p><p>Source: {{if .source}}{{.source}}{{else}}n/a{{end}}</p><p>Company name: {{if .company_name}}{{.company_name}}{{else}}n/a{{end}}</p>&zwnj;<p>Login to our web app to contact this lead. Update the stage and manage your sales. That way, you will have more time to do the things that matter without scrambling through your contacts!</p><p>This is a system generated email notification to inform you that you have received a lead on MHub CRM. Do not reply to this email.</p>&zwnj;<p>{{if .url}}{{.url}}{{else}}n/a{{end}}</p>',
      workflowAutomationActionTypeID: 'default_notify_lead_email',
      workflowAutomationID: 'default-lead-automation',
      workflowID: leadDefaultWorkflowID,
      workflowTypeID: 'lead_notification',
    },
    {
      id: 'action-id-2',
      sendEmailOn: '* * * * *',
      sendEmailRecipients: 'Assignee',
      sendEmailSubject: 'You have been assigned a new opportunity',
      sendEmailTemplate: '<p>Hi {{if .assignee_name}}{{.assignee_name}}{{else}}n/a{{end}},</p><p>A new opportunity has been assigned to you.</p>&zwnj;<p>Name: {{if .name}}{{.name}}{{else}}n/a{{end}}</p><p>Project name: {{if .project_names}}{{range $i, $project_name := .project_names}}{{if $i}}, {{end}}{{$project_name}}{{end}}{{else}}n/a{{end}}</p><p>Source: {{if .source}}{{.source}}{{else}}n/a{{end}}</p><p>Company name: {{if .company_name}}{{.company_name}}{{else}}n/a{{end}}</p>&zwnj;<p>Login to our web app to contact this lead. Update the stage and manage your sales. That way, you will have more time to do the things that matter without scrambling through your contacts!</p><p>This is a system generated email notification to inform you that you have received a lead on MHub CRM. Do not reply to this email.</p>&zwnj;<p>{{if .url}}{{.url}}{{else}}n/a{{end}}</p>',
      workflowAutomationActionTypeID: 'default_notify_opportunity_email',
      workflowAutomationID: 'default-lead-automation',
      workflowID: leadDefaultWorkflowID,
      workflowTypeID: 'lead_notification',
    },
  ],
  conditions: [
    {
      id: 'condition-id',
      subject: 'lead_record',
      field: 'assigned_at',
      operator: '==',
      value: 'now',
      valueType: 'string',
      workflowAutomationID: 'default-lead-automation',
      workflowID: leadDefaultWorkflowID,
      workflowTypeID: 'lead_notification',
    },
    {
      id: 'condition-id',
      subject: 'lead_record',
      field: 'assigned_to',
      operator: 'exists',
      workflowAutomationID: 'default-lead-automation',
      workflowID: leadDefaultWorkflowID,
      workflowTypeID: 'lead_notification',
    },
    {
      id: 'condition-id',
      subject: 'lead_record',
      field: 'imported_at',
      operator: 'notexists',
      workflowAutomationID: 'default-lead-automation',
      workflowID: leadDefaultWorkflowID,
      workflowTypeID: 'lead_notification',
    },
  ],
};
