/* eslint-disable import/no-cycle */
import {
  lazy,
  Suspense,
} from 'react';
import {
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { AppSnackbar } from '../components';
import LoadingPage from '../pages/LoadingPage';
import RouteAuthenticated from './RouteAuthenticated';
import routes from './routes';

export { routes };

const List = lazy(() => import('../pages/List'));
const ListCaseManagement = lazy(() => import('../pages/ListCaseManagement'));
const Workflow = lazy(() => import('../pages/Workflow'));
const NewAutomation = lazy(() => import('../pages/NewAutomation'));
const AccessDenied = lazy(() => import('../pages/AccessDenied'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Error = lazy(() => import('../pages/ErrorPage'));

export default function Routes() {
  return (
    <>
      <AppSnackbar />
      <Suspense fallback={<LoadingPage isLoading />}>
        <Switch>
          <RouteAuthenticated
            exact
            path={routes.workflowList(':type')}
            component={List}
          />
          <RouteAuthenticated
            exact
            path={routes.caseManagementList(':type')}
            component={ListCaseManagement}
          />
          <RouteAuthenticated
            exact
            path={routes.newWorkflow(':type')}
            component={Workflow}
          />
          <RouteAuthenticated
            exact
            path={routes.readWorkflow(':type', ':id')}
            component={Workflow}
          />
          <RouteAuthenticated
            exact
            path={routes.newAutomation(':type', ':workflowID')}
            component={NewAutomation}
          />
          <RouteAuthenticated
            exact
            path={routes.readAutomation(':type', ':workflowID', ':id')}
            component={NewAutomation}
          />
          <Route path={routes.error} exact component={Error} />
          <Route path={routes.accessDenied} exact component={AccessDenied} />
          <Route path={routes.notFound} exact component={NotFound} />
          <Redirect to={routes.notFound} />
        </Switch>
      </Suspense>
    </>
  );
}
