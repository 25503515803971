import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  fontColors,
  fontSize,
  tertiaryColors,
} from '../../styles/variables';

export default makeStyles((theme) => (({
  container: {
    paddingBottom: '5rem',
  },
  wordCount: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    top: theme.spacing(2.25),
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: 400,
    },
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  tagDialogCaption: {
    color: fontColors.subBody,
    marginBottom: 0,
  },
  ctaButton: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    width: '350px',
    padding: '1rem 7rem 1rem 1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  greyText: {
    color: tertiaryColors.grey,
  },
  scheduleBox: {
    padding: '1rem',
    backgroundColor: tertiaryColors.lightGrey,
  },
  infoIcon: {
    fontSize: fontSize['15px'],
    color: fontColors.body,
  },
})));
