/* eslint-disable max-len */
import { RawDraftContentState } from 'draft-js';
import {
  array, object, string,
} from 'yup';
import {
  AutomationFormAction,
  AutomationFormCondition,
  AutomationFormValues,
  DropdownOption,
  WorkflowTypeID,
} from '../../types';
import { AutocompleteOption } from '../../components/AutocompleteInput/types';

interface CreditControlAction {
  name: WorkflowTypeID;
  types: AutocompleteOption[];
}

export const creditControlActions: CreditControlAction[] = [
  {
    name: 'post_sales_tracking',
    types: [
      { label: 'Complete workflow stage', value: 'complete_workflow_stage' },
      { label: 'Complete workflow stage task', value: 'complete_workflow_stage_task' },
      { label: 'Update case status', value: 'update_workflow_case_status' },
      { label: 'Send email notification', value: 'send_email' },
      { label: 'Send in-app notification', value: 'send_inapp_notification' },
    ],
  },
];

export const initialConditionInput: AutomationFormCondition = {
  workflowTypeID: '',
  workflowID: '',
  workflowAutomationID: '',
  subject: '',
  field: '',
  operator: '',
  value: '',
  valueType: '',
};

export const initialActionInput: AutomationFormAction = {
  workflowTypeID: '',
  workflowID: '',
  workflowAutomationID: '',
  workflowAutomationActionTypeID: '',
  sendEmailSubject: '',
  sendEmailRecipients: '',
  sendEmailTemplate: '',
  sendInAppNotificationUserIDs: '',
  sendInAppNotificationUserGroups: '',
  sendInAppNotificationSetting: {
    titleTemplate: '',
    titleTemplateContentState: {} as RawDraftContentState,
    messageTemplate: '',
    messageTemplateContentState: {} as RawDraftContentState,
  },
};

export const initialValues: AutomationFormValues = {
  name: '',
  enabled: true,
  workflowTypeID: '',
  workflowID: '',
  rulesetLinking: 'AND',
  conditions: [initialConditionInput],
  actions: [],
};

export const validationSchema = object().shape({
  name: string().required('Label is required'),
  conditions: array()
    .of(
      object().shape({
        subject: string().required('Condition subject is required'),
        field: string().required('Condition field is required'),
        operator: string().required('Condition operator is required.'),
        value: string().required('Condition value is required.'),
      }),
    )
    .min(1, 'At least 1 condition is required'),
  actions: array()
    .of(
      object().shape({
        workflowAutomationActionTypeID: string().required(),
        updateStatusWorkflowCaseID: string().when('workflowAutomationActionTypeID', {
          is: 'update_workflow_case_status',
          then: (schema) => schema.required(),
        }),
        updateStatusWorkflowCaseStatus: string().when('workflowAutomationActionTypeID', {
          is: 'update_workflow_case_status',
          then: (schema) => schema.required(),
        }),
        completeWorkflowStageID: string().when('workflowAutomationActionTypeID', {
          is: 'complete_workflow_stage',
          then: (schema) => schema.required(),
        }),
        completeWorkflowStageTaskID: string().when('workflowAutomationActionTypeID', {
          is: 'complete_workflow_stage_task',
          then: (schema) => schema.required(),
        }),
        sendEmailTemplate: string().when('workflowAutomationActionTypeID', {
          is: 'send_email',
          then: (schema) => schema.required(),
        }),
        sendEmailSubject: string().when('workflowAutomationActionTypeID', {
          is: 'send_email',
          then: (schema) => schema.required(),
        }),
        sendEmailRecipients: string().when('workflowAutomationActionTypeID', {
          is: 'send_email',
          then: (schema) => schema.required(),
        }),
        sendInAppNotificationUserIDs: string().when('workflowAutomationActionTypeID', {
          is: 'send_inapp_notification',
          then: (schema) => schema.required(),
        }),
        sendInAppNotificationUserGroups: string().when('workflowAutomationActionTypeID', {
          is: 'send_inapp_notification',
          then: (schema) => schema.required(),
        }),
        sendInAppNotificationSetting: object().when('workflowAutomationActionTypeID', {
          is: 'send_inapp_notification',
          then: object().shape({
            titleTemplate: string().required(),
            messageTemplate: string().required(),
          }),
        }),
      }),
    ),
});

// Automation field condition options //

export const subjectTypeOptions = (
  type: string,
  values: AutomationFormValues,
  val: AutomationFormCondition,
): DropdownOption[] => {
  let options: DropdownOption[] = [];
  let distinctOptions: DropdownOption[];
  let generalOptions: DropdownOption[];
  let distinctSelectCount: number;

  switch (type) {
    case 'credit_control':
      distinctOptions = [
        { label: 'Post sales tracking', value: 'ps_tracking' },
      ];
      generalOptions = [
        { label: 'Project', value: 'project' },
      ];
      options = [
        ...distinctOptions,
        ...generalOptions,
      ];

      // count of selected distinct option
      distinctSelectCount = values.conditions.reduce((count, el, idx) => {
        if (values.conditions.length === idx + 1) {
          return count;
        }

        const isMatch = distinctOptions.some(
          (dopt) => dopt.value === el.subject,
        );

        return count + (isMatch ? 1 : 0);
      }, 0);

      options = options
        .filter((option) => {
          const otherSelections = values.conditions
            .filter(({ subject }) => subject !== val.subject);
          return otherSelections
            .every((selected) => selected.subject !== option.value);
        });

      if (distinctSelectCount > 0) {
        options = options
          .filter((opt) => {
            if (opt.value === val.subject) {
              return true;
            }
            return !distinctOptions.some((dopt) => dopt.value === opt.value);
          });
      }
      break;
    default:
      break;
  }

  return options;
};

export const fieldTypeOptions = (
  type: string,
  workflowTypeID?: string,
  subject?: string,
) => {
  let options: DropdownOption[] = [];

  if (subject) {
    switch (type) {
      case 'credit_control':
        if (['ps_tracking'].includes(subject)) {
          options = [
            { label: 'Status', value: 'status' },
          ];
        } else if (subject === 'project') {
          options = [
            { label: 'Name', value: 'id' },
          ];
        }
        break;
      default:
        break;
    }
  }

  return options;
};

export const operatorTypeOptions = (
  type: string,
) => {
  let options: DropdownOption[] = [];
  switch (type) {
    case 'credit_control':
      options = [
        { label: 'is', value: '==' },
      ];
      break;
    default:
      break;
  }

  return options;
};

export const valueTypeOptions = (
  type: string,
  subject?: string,
  field?: string,
  asyncOptions?: DropdownOption[],
) => {
  let options: DropdownOption[] = [];
  const loanStatusValues = [
    { label: 'Financier first payment', value: 'financier_first_payment' },
    { label: 'Letter of differential sum settled', value: 'letter_of_differential_sum_settled' },
    { label: 'Letter of differential sum unsettled', value: 'letter_of_differential_sum_unsettled' },
    { label: 'Loan agreement', value: 'loan_agreement' },
    { label: 'LOU received', value: 'lou_received' },
    { label: 'LOU replied', value: 'lou_replied' },
    { label: 'Purchaser portion fully paid', value: 'purchaser_portion_fully_paid' },
    { label: 'Letter of advice released', value: 'letter_of_advice_released' },
    { label: 'Memorandum of transfer entitled', value: 'memorandum_of_transfer_entitled' },
  ];

  if (subject && field) {
    switch (type) {
      case 'credit_control':
        if (subject === 'ps_tracking') {
          options = [
            { label: 'Booked', value: 'booked' },
            { label: 'Loan offerred', value: 'loan_offered' },
            { label: 'SPA appointment fixed', value: 'spa_appointment_fixed' },
            { label: 'SPA signed', value: 'spa_signed' },
            { label: 'SPA stamped', value: 'spa_stamped' },
            { label: 'SPA reminder sent', value: 'spa_reminder_sent' },
            { label: 'Loan reminder sent', value: 'loan_reminder_sent' },
            ...loanStatusValues,
          ];
        } else if (subject === 'project') {
          options = asyncOptions || [];
        }
        break;
      default:
        break;
    }
  }

  return options;
};
