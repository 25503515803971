import { API } from 'aws-amplify';

import { API_NAME_WORKFLOW } from '../constants';
import type { WorkflowAutomation } from '../types';

export interface ListWorkflowAutomationInput {
  limit?: number;
  page?: number;
  sortColumn?: string;
  sortDirection?: string;
  companyID? : string;
  origin?: string;
  fields?: string;
  filters?: string;
}

// To list out automations of a workflow
export const listWorkflowAutomations = (
  workflowID: string,
  input?: ListWorkflowAutomationInput,
) => API.get(API_NAME_WORKFLOW, `/automation/${workflowID}`, { queryStringParameters: input });

// To list out automations without workflow ID
export const listAutomations = (
  input?: ListWorkflowAutomationInput,
) => API.get(API_NAME_WORKFLOW, '/workflows', { queryStringParameters: input });

export type CreateWorkflowAutomationInput = Pick<
WorkflowAutomation,
| 'name'
| 'enabled'
| 'workflowTypeID'
| 'workflowID'
| 'conditions'
| 'actions'
>;

export const createWorkflowAutomation = (
  input: CreateWorkflowAutomationInput,
) => API.post(API_NAME_WORKFLOW, '/automation', { body: input });

export const readWorkflowAutomation = (
  automationID: string,
) => API.get(API_NAME_WORKFLOW, '/automation', { queryStringParameters: { id: automationID } });

export type UpdateWorkflowAutomationInput = Partial<
Pick<
WorkflowAutomation,
| 'name'
| 'enabled'
| 'conditions'
| 'actions'
>
>;

export const updateWorkflowAutomation = (
  automationID: string,
  input: UpdateWorkflowAutomationInput,
) => API.put(API_NAME_WORKFLOW, `/automation/${automationID}`, { body: input });

export const workflowAutomationCC = (
  workflowID: string,
  input: UpdateWorkflowAutomationInput,
) => API.put(API_NAME_WORKFLOW, `/workflows/${workflowID}/automations`, { body: input });

export const deleteWorkflowAutomation = (
  workflowAutomationID: string,
) => API.del(API_NAME_WORKFLOW, `/automation/${workflowAutomationID}`, {});
