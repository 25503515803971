export const parseErrorMessage = (data?: string, errorFieldName?: string) => {
  let result: string = '';

  if (data) {
    try {
      const key = errorFieldName || 'errorMessage';
      result = JSON.parse(data)?.[key];
    } catch (e) {
      result = data;
    }
  }

  return result;
};

export const parseURLHash = (hash: string) => parseInt(hash.slice(1) || '0', 10);
