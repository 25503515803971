import {
  makeStyles,
} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingBottom: '5rem',
  },
  ctaButton: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    width: '350px',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '1rem 7rem 1rem 1rem',
    },
  },
}));
