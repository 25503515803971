const routes = {
  workflowList: (type: string): string => `/workflow/${type}`,
  caseManagementList: (type: string): string => `/workflow/${type}/case_management`,
  newWorkflow: (type: string): string => `/workflow/${type}/new`,
  readWorkflow: (type: string, id: string): string => `/workflow/${type}/${id}`,
  newAutomation: (type: string, workflowID: string): string => `/automation/${type}/${workflowID}`,
  readAutomation: (type: string, workflowID: string, id: string): string => `/automation/${type}/${workflowID}/${id}`,
  notFound: '/not_found',
  accessDenied: '/access_denied',
  error: '/error',
};

export default routes;
