import {
  makeStyles,
} from '@material-ui/core/styles';
import { secondaryColors, tertiaryColors } from '../../styles/variables';

export default makeStyles({
  container: {
    padding: '0.5rem',
    '& > div': {
      marginRight: '0.5rem',
    },
    '&.info': {
      backgroundColor: tertiaryColors.lightBlue,
      '& > div > svg': {
        color: secondaryColors.main,
      },
    },
    '&.warning': {
      backgroundColor: tertiaryColors.mustard,
      '& > div > svg': {
        color: tertiaryColors.marigold,
      },
    },
  },
});
