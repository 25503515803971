import {
  ComponentType,
  useEffect,
} from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
} from 'react-router-dom';
import { useAuth } from '../hooks';
import LoadingPage from '../pages/LoadingPage';

export interface RouteProps {
  component: ComponentType<any>;
  exact?: boolean;
  path: string | string[];
}

const Authenticate = ({
  component: C,
  props,
}: {
  component: ComponentType<any>,
  props: RouteComponentProps,
}) => {
  const {
    authenticated,
    loading,
    authenticate,
  } = useAuth();

  useEffect(() => {
    if (!authenticated && loading) {
      authenticate();
    }
  }, [authenticated]);

  if (loading) return <LoadingPage isLoading />;
  if (authenticated) {
    return <C {...props} />;
  }
  return <Redirect to="/access_denied" />;
};

const RouteAuthenticated = ({
  component: C,
  ...rest
}: RouteProps) => (
  <Route
    {...rest}
    render={(props) => (
      <Authenticate component={C} props={props} />
    )}
  />
);

export default RouteAuthenticated;
