const config = {
  app: {
    stage: process.env.REACT_APP_RELEASE_STAGE,
  },
  cognito: {
    appClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    cookieDomain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
    cookieSecure: process.env.REACT_APP_COGNITO_COOKIE_SECURE,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  },
  api: {
    workflowApiUrl: process.env.REACT_APP_WORKFLOW_API_URL,
    userApiUrl: process.env.REACT_APP_USER_API_URL,
    leadCampaignApiUrl: process.env.REACT_APP_LEAD_CAMPAIGN_API_URL,
    leadFormApiUrl: process.env.REACT_APP_LEAD_FORM_API_URL,
    creditControlApiUrl: process.env.REACT_APP_CREDIT_CONTROL_GRAPHQL_API_URL,
    creditControlRESTApiUrl: process.env.REACT_APP_CREDIT_CONTROL_SERVICE_ENDPOINT,
    leadUsersApiUrl: process.env.REACT_APP_LEAD_USERS_API_URL,
    leadCallApiUrl: process.env.REACT_APP_LEAD_CALL_API_URL,
  },
};

export default config;
