import React from 'react';

import { Chip, ChipProps } from '@material-ui/core';

import useStyles from './ChipFilter.styles';

const ChipFilter = React.forwardRef((props: ChipProps, ref: React.Ref<HTMLDivElement>) => {
  const classes = useStyles();
  return (
    <Chip
      ref={ref}
      classes={classes}
      clickable
      {...props}
    />
  );
});

export default ChipFilter;
