import {
  API_NAME_WORKFLOW,
  API_NAME_USER,
  API_NAME_CREDIT_CONTROL,
} from '../constants';
import config from './config';

const {
  cookieDomain,
  cookieSecure,
  identityPoolId,
  region,
  userPoolId,
  appClientId,
} = config.cognito;

const {
  workflowApiUrl,
  userApiUrl,
  creditControlRESTApiUrl,
} = config.api;

const amplifyConfig = {
  Auth: {
    cookieStorage: {
      domain: cookieDomain,
      secure: cookieSecure,
    },
    identityPoolId,
    mandatorySignIn: true,
    region,
    userPoolId,
    userPoolWebClientId: appClientId,
  },
  API: {
    endpoints: [
      {
        name: API_NAME_WORKFLOW,
        endpoint: workflowApiUrl,
        region,
      },
      {
        name: API_NAME_USER,
        endpoint: userApiUrl,
        region,
      },
      {
        name: API_NAME_CREDIT_CONTROL,
        endpoint: creditControlRESTApiUrl,
        region,
      },
    ],
  },
};

export default amplifyConfig;
