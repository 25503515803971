import {
  makeStyles,
} from '@material-ui/core/styles';
import { fontColors, fontSize, tertiaryColors } from '../../styles/variables';

export default makeStyles((theme) => ({
  container: {
    paddingBottom: '5rem',
  },
  scheduleBox: {
    backgroundColor: tertiaryColors.lightGrey,
    padding: '0.5rem 1rem 1rem 1rem',
  },
  timeIcon: {
    color: fontColors.body,
    opacity: 0.6,
  },
  placeholder: {
    color: fontColors.subBody,
  },
  customMobileSection: {
    marginLeft: '-11px',
    marginTop: '0.5rem',
  },
  infoIcon: {
    fontSize: fontSize['15px'],
    color: fontColors.body,
    marginLeft: theme.spacing(1),
  },
  ctaButton: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    width: '350px',
    padding: '1rem 7rem 1rem 1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  phoneNumber: {
    color: tertiaryColors.lighterGrey,
  },
  deletedValue: {
    '& > div > div': {
      color: fontColors.subBody,
    },
  },
}));
