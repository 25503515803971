export function humanizeString(
  str: string,
  delimiter: string = ' ',
  toKey: boolean = false,
) {
  if (str) {
    if (!toKey) {
      const newString = str.charAt(0).toUpperCase() + str.slice(1);
      return newString.replace(/_/g, delimiter);
    }
    const newString = str.toLowerCase();
    return newString.replace(/ /g, delimiter);
  }
  return '';
}

export function convertArrayToString(
  array?: string[],
) {
  if (array && Array.isArray(array) && array?.length > 0) {
    return array.join(',');
  }
  return '';
}

export function convertStringToArray(
  str?: string,
  delimiter: string = ',',
) {
  if (str && typeof str === 'string') {
    return str.split(delimiter);
  }
  return [];
}
