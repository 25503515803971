import React from 'react';

import { Button, Grid } from '@material-ui/core';

import Drawer from '../Drawer';
import { DrawerProps } from '../Drawer/types';
import useStyles from './styles';

export interface FilterDrawerProps extends DrawerProps {
  onClear?: () => void;
  onSubmit?: () => void;
}

const FilterDrawer = ({
  children,
  onClose,
  onClear,
  onSubmit,
  title = 'Filter',
  ...other
}: FilterDrawerProps) => {
  const classes = useStyles();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit?.();
  };

  return (
    <Drawer
      onClose={onClose}
      title={title}
      {...other}
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Grid className={classes.gridContainer} container direction="column" spacing={2}>
          {React.Children.map(children, (child) => (
            <Grid item>
              {child}
            </Grid>
          ))}
        </Grid>
        <Grid container justifyContent="space-between">
          <Button size="small" className={classes.buttonClearAll} onClick={onClear}>
            Clear all
          </Button>
          <div className={classes.buttonWrapper}>
            <Button variant="outlined" onClick={onClose}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
            >
              Apply
            </Button>
          </div>
        </Grid>
      </form>
    </Drawer>
  );
};

export default FilterDrawer;
