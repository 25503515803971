import { useState } from 'react';
import AutocompleteInput from '../AutocompleteInput';
import { AutocompleteOption } from '../AutocompleteInput/types';

import DatePicker from '../DatePicker';
import FilterDrawer, { FilterDrawerProps } from '../FilterDrawer';

export interface FilterDrawerControlledProps extends Omit<FilterDrawerProps, 'onSubmit'> {
  fields: FilterDrawerControlledField[];
  onSubmit: (values: any) => void;
}

export interface FilterDrawerControlledField {
  name: string;
  label: string;
  value: any;
  type: 'daterange' | 'autocomplete';
  options?: any[];
  disabled?: boolean;
  loading?: boolean;
  onChange?: (value: any) => void;
}

const FilterDrawerControlled = ({
  fields,
  onClear,
  onSubmit,
  ...other
}: FilterDrawerControlledProps) => {
  const [values, setValues] = useState(fields.reduce((res, field) => ({
    ...res,
    [field.name]: field.value,
  }), {}));

  const handleClear = () => {
    setValues({});
    onClear?.();
  };

  const renderDaterangeInput = (field: FilterDrawerControlledField) => (
    <DatePicker
      key={field.name}
      name={field.name}
      label={field.label}
      value={values[field.name as keyof typeof values]}
      onChange={(v) => setValues((prevValues) => ({
        ...prevValues,
        [field.name]: v,
      }))}
      onClear={() => setValues((prevValues) => ({
        ...prevValues,
        [field.name]: null,
      }))}
    />
  );

  const renderAutocompleteInput = (field: FilterDrawerControlledField) => (
    <AutocompleteInput
      required
      key={field.name}
      name={field.name}
      label={field.label}
      placeholder="Search"
      options={field.options || []}
      loading={field.loading}
      disabled={field.disabled}
      value={values[field.name as keyof typeof values]}
      onChange={(e, value) => {
        field.onChange?.(value);
        setValues((prevValues) => ({
          ...prevValues,
          [field.name]: value,
        }));
      }}
      keySelector={(option: AutocompleteOption) => option.value}
      labelSelector={(option: AutocompleteOption) => option.label}
    />
  );

  return (
    <FilterDrawer
      {...other}
      onClear={handleClear}
      onSubmit={() => onSubmit?.(values)}
    >
      {fields.map((field) => {
        switch (field.type) {
          case 'daterange':
            return renderDaterangeInput(field);
          case 'autocomplete':
            return renderAutocompleteInput(field);
          default:
            return undefined;
        }
      })}
    </FilterDrawer>
  );
};

export default FilterDrawerControlled;
