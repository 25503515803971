import {
  isFunction,
  has,
  get,
} from 'lodash';
import { RenderPropsProps } from '../types';

export function getSelectorValue<T>(
  data: T,
  selector?: RenderPropsProps<[T], string | number | undefined>,
) {
  if (selector) {
    if (isFunction(selector)) {
      return selector(data);
    }
    if (has(data, selector)) {
      return get(data, selector);
    }
  }
  return data;
}
