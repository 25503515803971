import {
  CircularProgress,
  Typography,
  Grid,
} from '@material-ui/core';
import { LoadingPageProps } from './types';
import useStyles from './styles';

const LoadingPage = (
  {
    error = false,
    isLoading,
  } : LoadingPageProps,
) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.loadingPage}
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (error) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.loadingPage}
      >
        <Typography variant="h3">
          Sorry, there was a problem loading the page.
        </Typography>
      </Grid>
    );
  }
  return null;
};

export default LoadingPage;
