import { createTheme } from '@material-ui/core/styles';
import {
  fontSize,
  fontFamily,
  fontWeight,
  primaryColors,
  secondaryColors,
  tertiaryColors,
  fontColors,
  fontVariants,
} from './variables';

const defaultTheme = createTheme();

const globalTheme = (createTheme as any)({
  shadows: Array(25).fill('none'),
  palette: {
    primary: primaryColors,
    secondary: secondaryColors,
    tertiary: tertiaryColors,
    font: fontColors,
    text: {
      primary: fontColors.header,
    },
    error: {
      main: tertiaryColors.red,
      dark: defaultTheme.palette.error.dark,
      light: tertiaryColors.alertRed,
    },
  },

  typography: {
    fontFamily: fontFamily.openSans,
    ...fontVariants,
  },

  overrides: {
    MuiTypography: {
      colorTextPrimary: {
        color: fontColors.header,
      },
      colorTextSecondary: {
        color: fontColors.body,
      },
    },

    MuiButton: {
      root: {
        minWidth: '70px',
        height: '35px',
        '&:disabled': {
          '& img': {
            opacity: '0.5',
          },
        },
      },
      label: {
        textTransform: 'none',
        '&:disabled': {
          backgroundColor: tertiaryColors.mediumGrey,
        },
      },
      sizeSmall: {
        '& $label': {
          fontWeight: fontWeight.regular,
          fontSize: fontSize['12px'],
        },
        minWidth: '3.5rem',
        height: '1.875rem',
      },
      sizeLarge: {
        '& $label': {
          fontWeight: fontWeight.semiBold,
          fontSize: fontSize['16px'],
        },
        minWidth: '6.5rem',
        height: '2.5rem',
      },
      containedSecondary: {
        '& $label': {
          fontWeight: fontWeight.semiBold,
        },
        '&:hover': {
          backgroundColor: secondaryColors.light,
        },
        '&:active': {
          backgroundColor: secondaryColors.dark,
        },
        '&:focus': {
          backgroundColor: secondaryColors.dark,
        },
        '&:disabled': {
          backgroundColor: tertiaryColors.mediumGrey,
          color: tertiaryColors.grey,
        },
      },
    },

    MuiChip: {
      root: {
        color: fontColors.body,
        backgroundColor: tertiaryColors.mediumGrey,
      },
      clickable: {
        '&:hover': {
          backgroundColor: tertiaryColors.darkGrey,
        },
      },
    },

    MuiCheckbox: {
      root: {
        '& > span:first-child svg': {
          width: '18px',
          height: '18px',
        },
      },
      colorSecondary: {
        color: tertiaryColors.grey,
        '&$checked': {
          color: tertiaryColors.green,
        },
        '&$disabled': {
          color: tertiaryColors.darkGrey,
        },
      },
    },

    MuiTableRow: {
      hover: {
        '&$hover:hover': {
          backgroundColor: tertiaryColors.lightGrey,
        },
      },
    },

    MuiTableCell: {
      root: {
        padding: '0.5rem',
        borderBottom: `1px solid ${tertiaryColors.mediumGrey}`,
      },
      head: {
        ...fontVariants.subtitle1,
        borderBottom: `1px solid ${tertiaryColors.grey}`,
        whiteSpace: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '18rem',
      },
      stickyHeader: {
        backgroundColor: defaultTheme.palette.common.white,
      },
      body: {
        ...fontVariants.body1,
        color: fontColors.body,
      },
    },

    MuiSwitch: {
      root: {
        width: 30,
        height: 15,
        padding: 0,
        margin: defaultTheme.spacing(1),
      },
      colorSecondary: {
        '&$thumb': {
          backgroundColor: tertiaryColors.grey,
        },
        '&$checked': {
          color: defaultTheme.palette.common.white,
          '& + $track': {
            backgroundColor: tertiaryColors.green,
          },
          '&$disabled': {
            color: defaultTheme.palette.common.white,
            '& + $track': {
              backgroundColor: tertiaryColors.mediumGrey,
            },
          },
        },
        '&$disabled': {
          color: defaultTheme.palette.common.white,
          '& + $track': {
            backgroundColor: tertiaryColors.mediumGrey,
          },
        },
      },
      switchBase: {
        padding: 0,
        color: defaultTheme.palette.common.white,
        border: `1px solid ${tertiaryColors.mediumGrey}`,
        '&$checked': {
          transform: 'translateX(15px)',
          border: `1px solid ${primaryColors.main}`,
          '&$disabled': {
            border: `1px solid ${tertiaryColors.mediumGrey}`,
          },
          '& + $track': {
            opacity: 1,
          },
        },
        '&$disabled + $track': {
          opacity: 1,
        },
      },
      track: {
        borderRadius: 26 / 2,
        opacity: 1,
        transition: defaultTheme.transitions.create(['background-color', 'border', 'transform']),
        backgroundColor: tertiaryColors.grey,
      },
      thumb: {
        width: 13,
        height: 13,
      },
    },

    MuiTooltip: {
      tooltipPlacementBottom: {
        backgroundColor: fontColors.body,
        margin: '0.5rem 0 0 0',
        [defaultTheme.breakpoints.down('md')]: {
          margin: '0.5rem 0 0 0',
        },
      },
    },

    MuiPickersBasePicker: {
      pickerView: {
        width: '355px',
        maxWidth: '355px',
        '& .MuiPickersMonthSelection-container': {
          width: 'unset',
        },
        [defaultTheme.breakpoints.down('xs')]: {
          width: 'unset',
          minWidth: 'unset',
        },
      },
    },

    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '180px',
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        padding: '0 30px',
        '& > button:first-child': {
          paddingLeft: 0,
        },
        '& > button:last-child': {
          paddingRight: 0,
        },
        '& p': {
          fontSize: '12px',
        },
      },
      daysHeader: {
        '& $dayLabel': {
          color: fontColors.subBody,
          fontWeight: fontWeight.bold,
        },
      },
      transitionContainer: {
        '& > p': {
          color: fontColors.header,
          fontWeight: fontWeight.semiBold,
        },
      },
    },

    MuiPaper: {
      root: {
        border: `1px solid ${tertiaryColors.grey}`,
      },
    },

    MuiOutlinedInput: {
      root: {
        '&$notchedOutline': {
          borderColor: tertiaryColors.grey,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: `1px solid ${primaryColors.main}`,
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          border: `1px solid ${primaryColors.main}`,
        },
        '&$disabled $notchedOutline': {
          borderColor: tertiaryColors.mediumGrey,
        },
        '&$disabled': {
          backgroundColor: tertiaryColors.mediumGrey,
          '& img, & svg': {
            opacity: 0.5,
          },
        },
      },
      input: {
        border: '1px solid transparent',
        boxSizing: 'border-box',
        height: '40px',
        padding: '0.7rem 1rem',
      },
    },

    MuiRadio: {
      root: {
        '& .MuiSvgIcon-root': {
          height: '18px',
          width: '18px',
        },
      },
      colorSecondary: {
        color: tertiaryColors.grey,
        '&$disabled': {
          color: tertiaryColors.mediumGrey,
        },
        '&$checked': {
          color: tertiaryColors.green,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    MuiTabs: {
      flexContainer: {
        borderBottom: `1px solid ${tertiaryColors.darkGrey}`,
      },
      indicator: {
        backgroundColor: primaryColors.main,
      },
    },

    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          margin: 0,
        },
      },
    },

    MuiSvgIcon: {
      colorDisabled: {
        color: tertiaryColors.darkGrey,
      },
      colorError: {
        color: tertiaryColors.peach,
      },
    },

    MuiInputBase: {
      root: {
        '&:not(.Mui-disabled)': {
          backgroundColor: 'white',
        },
      },
    },

  },

  props: {
    MuiTab: {
      disableRipple: true,
      disableFocusRipple: true,
    },
  },
});

export default globalTheme;
