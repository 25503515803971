import { API } from 'aws-amplify';

import { API_NAME_USER } from '../constants';

export interface LisUsersInput {
  limit?: number;
  page?: number;
  search?:string; // supports name, email, mobile, aws_username
}

// return ALL Users' record directly streamed from AWS cognito but id is based on psql id
export const listUsers = (
  input?: LisUsersInput,
) => API.get(API_NAME_USER, '/users', { queryStringParameters: input });

// return company Users' record directly streamed from AWS cognito but id is cognito id
export const listAffiliates = (
  companyID: string,
  input?: LisUsersInput,
) => API.get(API_NAME_USER, '/affiliates', {
  headers: {
    'x-company-id': companyID,
  },
  queryStringParameters: input,
});
