import clsx from 'clsx';
import {
  Box, Drawer as MuiDrawer, Grid, IconButton, Typography,
} from '@material-ui/core';
import { NavigateBefore, Close } from '@material-ui/icons';
import { DrawerProps } from './types';
import useStyles from './styles';

const Drawer = ({
  children,
  onClose,
  open,
  title,
  onBack,
}: DrawerProps) => {
  const classes = useStyles();

  const renderTitle = () => (typeof title === 'string' ? (
    <Typography variant="h6">{title}</Typography>
  ) : (
    title
  ));

  return (
    <MuiDrawer
      anchor="right"
      open={open}
      elevation={0}
      onClose={onClose}
      classes={{
        paperAnchorRight: classes.drawer__paperAnchorRight,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        className={clsx(classes.sidePadding, classes.header)}
      >
        <Box display="flex" alignItems="center">
          {onBack && (
            <IconButton
              edge="start"
              onClick={onBack}
              className={classes.drawer__iconButton}
            >
              <NavigateBefore fontSize="large" />
            </IconButton>
          )}
          {renderTitle()}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton edge="end" onClick={onClose}>
            <Close className={classes.closeIcon} />
          </IconButton>
        </Box>
      </Grid>
      {children}
    </MuiDrawer>
  );
};

export default Drawer;
