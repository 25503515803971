import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { TextFieldLabelProps } from './types';
import useStyles from './styles';

const TextFieldLabel = ({
  children,
  required,
  className,
  ...TypographyProps
}: TextFieldLabelProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <Typography
      variant="subtitle2"
      gutterBottom
      className={clsx({ [classes.required]: required }, className)}
      {...TypographyProps}
    >
      {children}
    </Typography>
  );
};

export default TextFieldLabel;
