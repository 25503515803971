import { makeStyles } from '@material-ui/core';
import { fontColors } from '../../styles/variables';

export default makeStyles(() => ({
  wrapper: {
    '& > div:first-child': {
      borderRadius: '4px 0 0 4px',
      height: '40px',
    },
    '& > div:not(:first-child) > div': {
      borderRadius: '0 4px 4px 0',
      '& > fieldset': {
        borderLeft: 'none',
      },
    },
  },
  paper: {
    maxHeight: '250px',
  },
  flag: {
    marginRight: '0.5rem',
  },
  placeholder: {
    color: fontColors.subBody,
  },
}));
