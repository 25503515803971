import { makeStyles } from '@material-ui/core/styles';
import { fontColors, fontSize, tertiaryColors } from '../../styles/variables';

export function useStyles(props: any, isSmallerFont?: boolean, inactive?: boolean) {
  return makeStyles((theme) => ({
    root: {
      marginRight: '0.5rem',
      fontSize: isSmallerFont ? fontSize['12px'] : fontSize['14px'],
      fontColor: inactive ? fontColors.body : fontColors.header,
      '& > .MuiChip-avatar': {
        backgroundColor: inactive ? tertiaryColors.darkGrey : theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    },
  }))(props);
}
