import { useState } from 'react';

interface ToggleState {
  [key: string]: boolean
}

export function useToggleState<T extends ToggleState>(toggleStates: T) {
  const [activeStates, setActiveStates] = useState(toggleStates);

  const toggleState = (name: keyof T, state?: boolean) => {
    setActiveStates((prev) => ({
      ...prev,
      [name]: state || !prev[name],
    }));
  };

  return {
    activeStates,
    setActiveStates,
    toggleState,
  };
}
