import {
  useEffect,
  useState,
  useRef,
} from 'react';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import {
  IconButton,
  Typography,
  Icon,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import format from 'date-fns/format';
import moment from 'moment';
import { CalendarIcon } from '../../assets/icons';
import { DatePickerProps } from './types';
import { TextFieldLabel } from '..';
import useStyles from './styles';

export default function DatePicker(props: DatePickerProps) {
  const {
    value,
    name,
    label,
    simple,
    disableClearable,
    required,
    error,
    onChange,
    onClear,
    ...rest
  } = props;

  const classes = useStyles();
  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setAnchorEl(containerRef.current);
    }
  }, []);

  const handleOnChangeDate = (
    v: MaterialUiPickersDate,
  ) => {
    const formatted = moment(v).format('YYYY-MM-DD');
    if (simple) {
      onChange(formatted);
      return;
    }
    if (
      !(value && value.start)
      || (value.start && value.end)
      || (value.start && (moment(formatted).isBefore(moment(value.start))))
    ) {
      onChange({ start: formatted, end: null });
      return;
    }
    onChange({ start: value.start, end: formatted });
  };

  const handleOnClear = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    onClear();
  };

  const renderLabel = () => {
    if (simple && value) {
      return moment(value).format('DD MMM YYYY');
    }
    if (!simple && value && value.start && value.end) {
      return `${moment(value.start).format('DD MMM YYYY')} - ${moment(value.end).format('DD MMM YYYY')}`;
    }
    return 'Select';
  };

  const renderSelectedDay = (
    day: MaterialUiPickersDate,
    selectedDay: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element,
  ) => {
    if (simple) {
      return dayComponent;
    }

    const dateClone = day ? day.toDate() : 0;
    const date = moment(dateClone).format('YYYY-MM-DD');
    const start = value && value.start ? moment(value.start).format('YYYY-MM-DD') : null;
    const end = value && value.end ? moment(value.end).format('YYYY-MM-DD') : null;

    const dayIsBetween = (
      value
      && value.start
      && value.end
      && moment(dateClone).isBetween(moment(value.start), moment(value.end))
    );
    const isFirstDay = (date === start);
    const isLastDay = (date === end);
    const lastDayOfWeek = moment(moment(dateClone).endOf('week')).format('YYYY-MM-DD');
    const isLastDayOfWeek = moment(moment(dateClone).format('YYYY-MM-DD')).isSame(lastDayOfWeek);

    const wrapperClassName = `
      ${dayInCurrentMonth && value && value.start && isLastDay ? classes.endDateWrapper : classes.dayWrapper}
      ${isLastDayOfWeek ? classes.endDateWeekWrapper : ''}
      ${dayInCurrentMonth && dayIsBetween ? classes.highlight : ''}
      ${dayInCurrentMonth && isFirstDay ? classes.firstHighlight : ''}
      ${dayInCurrentMonth && isLastDay ? classes.endHighlight : ''}
    `;
    const dayClassName = `
      ${classes.day}
      ${(dayInCurrentMonth && isFirstDay) || (dayInCurrentMonth && isLastDay) ? classes.highlightDay : ''}
      ${!dayInCurrentMonth ? classes.nonCurrentMonthDay : ''}
    `;

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <Typography variant="body2">
            {format(dateClone, 'd')}
          </Typography>
        </IconButton>
      </div>
    );
  };

  const haveValue = (simple && value) || (!simple && value && value.start && value.end);
  return (
    <div key={name} ref={containerRef}>
      <TextFieldLabel required={required}>
        { label }
      </TextFieldLabel>
      <MuiDatePicker
        fullWidth
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        labelFunc={() => renderLabel()}
        required={required}
        error={!!error}
        helperText={error}
        value={value}
        renderDay={(
          day, selectedDate, dayInCurrentMonth, dayComponent,
        ) => renderSelectedDay(day, selectedDate, dayInCurrentMonth, dayComponent)}
        onChange={handleOnChangeDate}
        InputProps={{
          className: `${classes.input} ${haveValue ? '' : classes.placeholder}`,
          endAdornment: (
            <>
              {
                !disableClearable && (
                  <IconButton
                    className={`${classes.clearButton} ${haveValue ? classes.visibleButton : ''}`}
                    onClick={handleOnClear}
                  >
                    <CloseOutlined fontSize="small" />
                  </IconButton>
                )
              }
              <Icon className={classes.icon}>
                <img alt="date" src={CalendarIcon} />
              </Icon>
            </>
          ),
        }}
        PopoverProps={{
          anchorEl,
        }}
        {...rest}
      />
    </div>
  );
}
