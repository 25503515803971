import {
  makeStyles,
} from '@material-ui/core/styles';
import { fontColors, fontSize } from '../../styles/variables';

export default makeStyles((theme) => (({
  infoIcon: {
    fontSize: fontSize['15px'],
    color: fontColors.body,
    marginLeft: theme.spacing(1),
  },
})));
