import {
  makeStyles,
} from '@material-ui/core/styles';
import { fontVariants } from '../../styles/variables';

export default makeStyles((theme) => ({
  root: fontVariants.body2,
  colorPrimary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  deleteIcon: {
    display: 'none',
    height: '1.1rem',
  },
  deleteIconColorPrimary: {
    display: 'inline-block',
    color: theme.palette.common.white,
  },
}));
