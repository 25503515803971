import { darken, lighten, makeStyles } from '@material-ui/core/styles';
import { tertiaryColors } from '../../styles/variables';

export default makeStyles((theme) => ({
  root: {
    width: '450px',
    padding: '1.5rem 2rem',
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.5rem',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  heading: {
    marginBottom: '1rem',
  },
  errorButton: {
    backgroundColor: tertiaryColors.peach,
    '&:hover': {
      backgroundColor: lighten(tertiaryColors.peach, 0.2),
    },
    '&:active': {
      backgroundColor: darken(tertiaryColors.peach, 0.2),
    },
    '&:focus': {
      backgroundColor: darken(tertiaryColors.peach, 0.2),
    },
  },
}));
