import { makeStyles } from '@material-ui/core/styles';
import { fontColors } from '../../styles/variables';

export default makeStyles(() => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: 400,
    },
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogCaption: {
    color: fontColors.subBody,
    margin: '1rem 0 0 0',
  },
}));
