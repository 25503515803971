import { AutocompleteOption } from '../AutocompleteInput/types';

export const customTagOptions: AutocompleteOption[] = [
  {
    label: 'Assignee name',
    value: '{{if .assignee_name}}{{.assignee_name}}{{else}}n/a{{end}}',
  },
  {
    label: 'Company name',
    value: '{{if .company_name}}{{.company_name}}{{else}}n/a{{end}}',
  },
  {
    label: 'Lead/Opportunity URL',
    value: '{{if .url}}{{.url}}{{else}}n/a{{end}}',
  },
  {
    label: 'Full name',
    value: '{{if .name}}{{.name}}{{else}}n/a{{end}}',
  },
  {
    label: 'Preferred name',
    value: '{{if .preferred_name}}{{.preferred_name}}{{else}}n/a{{end}}',
  },
  {
    label: 'Project name(s)',
    value: '{{if .project_names}}{{range $i, $project_name := .project_names}}{{if $i}}, {{end}}{{$project_name}}{{end}}{{else}}n/a{{end}}',
  },
  {
    label: 'Source',
    value: '{{if .source}}{{.source}}{{else}}n/a{{end}}',
  },
  {
    label: 'Unit number',
    value: '{{if .unit_number}}{{.unit_number}}{{else}}n/a{{end}}',
  },
];
