import {
  useRef,
  useState,
} from 'react';
import {
  SelectionState,
  ContentState,
  convertToRaw,
  Editor,
  EditorState,
  ContentBlock,
  Modifier,
  convertFromHTML,
  RawDraftContentState,
  convertFromRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { escapeRegExp } from 'lodash';
import {
  Button,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import PersonaliseModal from '../PersonaliseModal';
import decorators from './Decorators';
import { ContentEditorProps } from './types';
import { customTagOptions } from './constants';
import { useToggleState } from '../../hooks';
import { TagIcon } from '../../assets/icons';
import { TextFieldLabel } from '..';
import useStyles from './styles';

const ContentEditor = ({
  label,
  defaultContents,
  maxLength,
  required,
  error,
  disabled = false,
  handleEditorTouched,
  hideButtons,
  onChange,
}: ContentEditorProps) => {
  const classes = useStyles();
  const editorRef = useRef<Editor>(null);

  const getInitValue = (
    values: RawDraftContentState | string,
  ) => {
    // If values is of type RawDraftContentState
    if (
      typeof values !== 'string'
      && 'blocks' in values
      && 'entityMap' in values
    ) {
      return EditorState.createWithContent(
        convertFromRaw(values),
        decorators(disabled),
      );
    }

    // If values is of type string
    const blocksFromHTML = convertFromHTML(values);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    return EditorState.createWithContent(state, decorators(disabled));
  };

  const [editorState, setEditorState] = useState<EditorState>(getInitValue(defaultContents));
  const { activeStates, toggleState } = useToggleState({
    personalise: false,
  });

  const contentIsEmpty = (es: EditorState) => {
    const currentContent = es.getCurrentContent();
    // Check if editor is empty or have only spaces
    const isEditorEmpty = !currentContent.hasText();
    const currentPlainText = currentContent.getPlainText();
    const lengthOfTrimmedContent = currentPlainText.trim().length;
    const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;

    return isEditorEmpty || isContainOnlySpaces;
  };

  const getContentLength = () => {
    const currentContent = editorState.getCurrentContent();
    const currentPlainText = currentContent.getPlainText();
    let totalLength = 0;

    if (currentPlainText && currentPlainText.length > 0) {
      let textLength = currentPlainText.length || 0;

      // One variable = 20 characters
      let totalVariableCharacters = 0;
      customTagOptions.forEach((tag) => {
        const matches = [
          ...currentPlainText.matchAll(new RegExp(escapeRegExp(tag.value), 'g')),
        ];
        if (matches.length > 0) {
          textLength -= tag.value.length;
          totalVariableCharacters += (matches.length * 20);
        }
      });
      totalLength = textLength + totalVariableCharacters;
    }
    return !contentIsEmpty(editorState) ? totalLength : 0;
  };

  const sendContent = (es: EditorState) => {
    const currentContent = es.getCurrentContent();
    if (contentIsEmpty(es)) {
      onChange('', {} as RawDraftContentState);
    } else {
      const rawContentState = convertToRaw(currentContent);
      const htmlContentState = draftToHtml(rawContentState);
      onChange(htmlContentState, rawContentState);
    }
  };

  const getInsertState = (selectedIndex: number, trigger: string) => {
    const currentSelectionState = editorState.getSelection();
    const end = currentSelectionState.getAnchorOffset();
    const anchorKey = currentSelectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();
    const start = blockText.substring(0, end).lastIndexOf(trigger);
    return {
      start,
      end,
      trigger,
      selectedIndex,
    };
  };

  const handleBeforeInput = () => {
    if (maxLength) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
      if (currentContentLength > maxLength - 1) {
        return 'handled';
      }
    }
    return 'not-handled';
  };

  const handlePastedText = (pastedText: string) => {
    if (maxLength) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
      return currentContentLength + pastedText.length > maxLength ? 'handled' : 'not-handled';
    }
    return 'not-handled';
  };

  const handleChange = (es: EditorState) => {
    let nextState = es;

    const contentBlocks = nextState
      .getCurrentContent()
      .getBlocksAsArray();

    contentBlocks.forEach((contentBlock: ContentBlock) => {
      const text = contentBlock.getText();

      const tokenBlockLocations = customTagOptions.map((tag) => {
        const tokenIndexesRaw = [
          ...text.matchAll(new RegExp(escapeRegExp(tag.value), 'g')),
        ].map((a) => a.index);
        const tokenIndexes: number[] = [];

        tokenIndexesRaw.forEach((tokenIndex) => {
          if (tokenIndex !== undefined) {
            tokenIndexes.push(tokenIndex);
          }
        });

        if (tokenIndexes.length > 0) {
          return { tokenValue: tag.value, tokenIndexes };
        }
        return null;
      });

      tokenBlockLocations.forEach((tokenBlockLocation) => {
        if (!tokenBlockLocation) return;

        const { tokenValue } = tokenBlockLocation;
        const { tokenIndexes } = tokenBlockLocation;

        const entityExistsAtIndex = tokenIndexes
          .some((tokenIndex) => contentBlock.getEntityAt(tokenIndex));
        if (entityExistsAtIndex) return;

        tokenIndexes.forEach((tokenIndex) => {
          // Add entity to each index
          const contentStateWithEntity = nextState
            .getCurrentContent()
            .createEntity('token', 'IMMUTABLE');
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

          const currentSelectionState = SelectionState.createEmpty(contentBlock.getKey());
          const begin = tokenIndex;
          const end = tokenIndex + tokenValue.length;

          const tokenText = currentSelectionState.merge({
            anchorOffset: begin,
            focusOffset: end,
          });

          let replacedTokenContent = Modifier.replaceText(
            nextState.getCurrentContent(),
            tokenText,
            tokenValue,
            undefined,
            entityKey,
          );

          // If the token is inserted at the end, a space is appended right after for
          // a smooth writing experience.
          const blockKey = tokenText.getAnchorKey();
          const blockSize = nextState
            .getCurrentContent()
            .getBlockForKey(blockKey)
            .getLength();
          if (blockSize === end) {
            replacedTokenContent = Modifier.insertText(
              replacedTokenContent,
              replacedTokenContent.getSelectionAfter(),
              ' ',
            );
          }

          nextState = EditorState.push(
            nextState,
            replacedTokenContent,
            'apply-entity',
          );

          nextState = EditorState.forceSelection(
            nextState,
            replacedTokenContent.getSelectionAfter(),
          );
        });
      });
    });

    sendContent(nextState);
    setEditorState(nextState);
  };

  const handleInsertVariable = (variable: string) => {
    const { start, end } = getInsertState(0, variable);
    const currentContent = editorState.getCurrentContent();
    const contentStateWithEntity = editorState
      .getCurrentContent()
      .createEntity('token', 'IMMUTABLE');
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const currentSelection = editorState.getSelection();
    const currentSelectionState = currentSelection.merge({
      anchorOffset: start,
      focusOffset: end,
    });

    let insertingContent = Modifier.insertText(
      currentContent,
      currentSelection,
      variable,
      undefined,
      entityKey,
    );

    const blockKey = currentSelectionState.getAnchorKey();
    const blockSize = editorState
      .getCurrentContent()
      .getBlockForKey(blockKey)
      .getLength();
    if (blockSize === end) {
      insertingContent = Modifier.insertText(
        insertingContent,
        insertingContent.getSelectionAfter(),
        ' ',
      );
    }

    const newEditorState = EditorState.push(
      editorState,
      insertingContent,
      'insert-characters',
    );

    const newES = EditorState.forceSelection(
      newEditorState,
      insertingContent.getSelectionAfter(),
    );

    sendContent(newES);
    setEditorState(newES);
    toggleState('personalise');
  };

  const handleFocusEditor = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <TextFieldLabel required={required}>{label}</TextFieldLabel>
        </Grid>
        {maxLength && maxLength > 0 && (
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {`${getContentLength()}/${maxLength} characters`}
            </Typography>
          </Grid>
        )}
      </Grid>
      <div
        className={`${classes.editor} ${disabled ? 'disabled' : ''}`}
        role="button"
        tabIndex={-1}
        onClick={handleFocusEditor}
        onKeyDown={handleFocusEditor}
      >
        <Editor
          ref={editorRef}
          placeholder="Type message here"
          readOnly={disabled}
          editorState={editorState}
          onChange={handleChange}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          onFocus={handleEditorTouched}
        />
      </div>
      {!hideButtons && (
        <Grid container className={classes.buttonContainer}>
          <Button
            startIcon={<img alt="tag" src={TagIcon} />}
            onClick={() => toggleState('personalise')}
            disabled={disabled}
          >
            Add Tag
          </Button>
        </Grid>
      )}
      <Grid container>
        {!!error && <FormHelperText error>{error}</FormHelperText>}
      </Grid>
      <PersonaliseModal
        open={activeStates.personalise}
        onSubmit={handleInsertVariable}
        onClose={() => toggleState('personalise')}
      />
    </>
  );
};

export default ContentEditor;
