import { makeStyles } from '@material-ui/core/styles';
import { fontColors } from '../../styles/variables';

export default makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(3),
  },
  gridContainer: {
    flex: 1,
  },
  buttonClearAll: {
    color: fontColors.subBody,
  },
  buttonWrapper: {
    '& > button:not(:first-child)': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(13),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(6),
      },
    },
  },
}));
