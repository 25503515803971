import { makeStyles } from '@material-ui/core/styles';
import { fontVariants } from '../../styles/variables';

export default makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'backgroundColor 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  header: {
    ...fontVariants.subtitle1,
    color: theme.palette.font.header,
    textTransform: 'uppercase',
    padding: '1.3rem 1.15rem 0.86rem 1.15rem',
  },
  item: {
    ...fontVariants.body2,
    color: theme.palette.font.header,
    minWidth: '6.25rem',
    '&:hover': {
      backgroundColor: `${theme.palette.tertiary.mediumGrey}`,
    },
  },
}));
