import {
  useState,
  useEffect,
} from 'react';
import { debounce } from 'lodash';
import {
  TextField as MuiTextField,
} from '@material-ui/core';
import { TextFieldLabel } from '..';
import { TextFieldProps } from './types';

// This is to reduce the formik rendering on each change
const DebouncedTextFieldInput = ({
  children,
  label,
  required = false,
  TextFieldLabelProps,
  InputProps,
  value,
  onChange,
  ...MuiTextFieldProps
}: TextFieldProps) => {
  const [innerValue, setInnerValue] = useState('');

  const debounceCallBack = debounce((e, callBack) => {
    callBack();
  }, 1000);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const newValue = e.target.value;
    setInnerValue(newValue);
    if (onChange) {
      debounceCallBack(e, () => {
        onChange(e);
      });
    }
  };

  useEffect(() => {
    if (value) {
      setInnerValue(value as string);
    } else {
      setInnerValue('');
    }
  }, [value]);

  return (
    <>
      {label && (
      <TextFieldLabel required={required} {...TextFieldLabelProps}>
        {label}
      </TextFieldLabel>
      )}
      <MuiTextField
        InputProps={{
          ...InputProps,
        }}
        {...MuiTextFieldProps}
        value={innerValue}
        onChange={handleOnChange}
      >
        {children}
      </MuiTextField>
    </>
  );
};

export default DebouncedTextFieldInput;
