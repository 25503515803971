import { Box, Button } from '@material-ui/core';
import
{
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import { pick } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { AutocompleteInput, ConfirmationModal, Drawer } from '..';
import { readWorkflow } from '../../apis';
import { useAlert, useLoader, useToggleState } from '../../hooks';
import { creditControlActions } from '../../pages/NewAutomation/formData';
import {
  DropdownOption, TParams, WorkflowStage,
} from '../../types';
import { getErrorMessage } from '../../utils';
import { AutocompleteOption } from '../AutocompleteInput/types';
import useStyles from './styles';
import { ConfigureCaseDrawerProps } from './types';

const ConfigureCaseDrawer = ({
  actionTypeID,
  formikProps,
  open,
  onClose,
  selectedActionIndex,
  selectedSuggestion,
  setActionTypeID,
}: ConfigureCaseDrawerProps) => {
  const classes = useStyles();
  const { type } = useParams<TParams>();
  const { setAlert } = useAlert();
  const [workflowStages, setWorkflowStages] = useState<any[]>([]);
  const { loaders, toggleLoadingState } = useLoader();
  const { activeStates, toggleState } = useToggleState({
    leaveConfirmation: false,
  });

  const listCases = async (workflowID: string) => {
    toggleLoadingState('loading', true);

    try {
      const res = await readWorkflow(workflowID);

      const formattedWorkflowStageOptions = res?.workflowStages?.map((stage: WorkflowStage) => ({
        label: stage?.name,
        value: stage?.id,
        stageTasks: stage?.workflowStageTasks?.map((task) => ({
          label: task?.name,
          value: task?.id,
          title: stage?.name,
        })) || [],
      })) || [];

      setWorkflowStages(
        formattedWorkflowStageOptions.filter(
          (option: DropdownOption) => option.label && option.value,
        ),
      );
    } catch (err) {
      setAlert(
        {
          message: getErrorMessage(err),
          severity: 'error',
        },
        true,
      );
    }

    toggleLoadingState('loading', false);
  };

  useEffect(() => {
    listCases(formikProps.values.workflowID);
  }, []);

  const getActionOptions = () => {
    let options: AutocompleteOption[] = [];
    if (type === 'credit_control') {
      if (selectedSuggestion) {
        const suggestionIndex = creditControlActions.findIndex(
          (action) => action.name === selectedSuggestion,
        );
        if (suggestionIndex > -1) {
          options = creditControlActions[suggestionIndex].types;
        }
      } else {
        options = [];
      }
    }
    return options;
  };

  const getTitle = () => creditControlActions[0]
    .types.find((actionType) => actionType.value === actionTypeID)?.label || 'N/A';

  const renderModal = () => (
    <ConfirmationModal
      open={activeStates.leaveConfirmation}
      onConfirm={onClose}
      onCancel={() => {
        toggleState('leaveConfirmation');
      }}
      type="leave"
    />
  );

  return (
    <>
      {renderModal()}
      <Formik
        initialValues={{
          ...formikProps.values.actions[selectedActionIndex as number],
          workflowAutomationActionTypeID: actionTypeID,
        }}
        onSubmit={(values) => {
          const workflowValues = [
            'workflowID',
            'workflowTypeID',
            'updateStatusWorkflowCaseID',
            'workflowAutomationID',
            'workflowAutomationActionTypeID',
          ];

          const updateCaseStatusValues = pick(
            values,
            'updateStatusWorkflowCaseStatus',
            ...workflowValues,
          );

          const completeStageValues = pick(
            values,
            'completeWorkflowStageID',
            ...workflowValues,
          );

          const completeStageTaskValues = pick(
            values,
            'completeWorkflowStageTaskID',
            ...workflowValues,
          );

          switch (values.workflowAutomationActionTypeID) {
            case 'update_workflow_case_status':
              formikProps.setFieldValue(
                `actions.${selectedActionIndex}`,
                updateCaseStatusValues,
              );
              break;
            case 'complete_workflow_stage':
              formikProps.setFieldValue(
                `actions.${selectedActionIndex}`,
                completeStageValues,
              );
              break;
            case 'complete_workflow_stage_task':
              formikProps.setFieldValue(
                `actions.${selectedActionIndex}`,
                completeStageTaskValues,
              );
              break;
            default:
          }

          if (values.id) {
            formikProps.setFieldValue(
              `actions.${selectedActionIndex}.id`,
              values.id,
            );
          }

          onClose();
        }}
        validationSchema={object({
          workflowAutomationActionTypeID: string().required(
            'Action type is required',
          ),
          updateStatusWorkflowCaseID: string().when(
            'workflowAutomationActionTypeID',
            {
              is: 'update_workflow_case_status',
              then: (schema) => schema.required('Workflow case is required'),
            },
          ),
          updateStatusWorkflowCaseStatus: string().when(
            'workflowAutomationActionTypeID',
            {
              is: 'update_workflow_case_status',
              then: (schema) => schema.required('Workflow case status is required'),
            },
          ),
          completeWorkflowStageID: string().when(
            'workflowAutomationActionTypeID',
            {
              is: 'complete_workflow_stage',
              then: (schema) => schema.required('Workflow stage is required'),
            },
          ),
          completeWorkflowStageTaskID: string().when(
            'workflowAutomationActionTypeID',
            {
              is: 'complete_workflow_stage_task',
              then: (schema) => schema.required('Workflow stage task is required'),
            },
          ),
        })}
        validateOnMount
      >
        {({
          touched,
          errors,
          setFieldValue,
          dirty,
          isValidating,
          isSubmitting,
          isValid,
          resetForm,
          values,
        }) => (
          <Drawer
            title={getTitle()}
            open={open}
            onClose={() => (dirty ? toggleState('leaveConfirmation') : onClose())}
          >
            <Box
              component={Form}
              p={2.5}
              height="100%"
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Box className={classes.container}>
                <Field
                  as={AutocompleteInput}
                  required
                  label="Action type"
                  id="workflowAutomationActionTypeID"
                  name="workflowAutomationActionTypeID"
                  fullWidth
                  placeholder="Select"
                  disableClearable
                  disabled={!!values.id}
                  options={getActionOptions()}
                  keySelector={(option: AutocompleteOption) => option.value}
                  labelSelector={(option: AutocompleteOption) => option.label}
                  TextFieldProps={{
                    error:
                      touched.workflowAutomationActionTypeID
                      && !!errors.workflowAutomationActionTypeID,
                    helperText: (
                      <ErrorMessage name="workflowAutomationActionTypeID" />
                    ),
                  }}
                  value={values.workflowAutomationActionTypeID}
                  onChange={(event: ChangeEvent<{}>, newValue: string) => {
                    setActionTypeID(newValue);
                    resetForm();
                    setFieldValue('workflowAutomationActionTypeID', newValue);
                  }}
                />
                <Box pb={2} />
                {values.workflowAutomationActionTypeID === 'update_workflow_case_status' && (
                  <>
                    <Field
                      as={AutocompleteInput}
                      required
                      label="Workflow case status"
                      id="updateStatusWorkflowCaseStatus"
                      name="updateStatusWorkflowCaseStatus"
                      fullWidth
                      placeholder="Select"
                      options={[
                        { value: 'open', label: 'Open' },
                        { value: 'pending', label: 'In progress' },
                        { value: 'closed', label: 'Inactive' },
                        { value: 'done', label: 'Completed' },
                      ]}
                      keySelector={(option: AutocompleteOption) => option.value}
                      labelSelector={(option: AutocompleteOption) => option.label}
                      TextFieldProps={{
                        error:
                          touched.updateStatusWorkflowCaseStatus
                          && !!errors.updateStatusWorkflowCaseStatus,
                        helperText: (
                          <ErrorMessage name="updateStatusWorkflowCaseStatus" />
                        ),
                      }}
                      onChange={(event: ChangeEvent<{}>, newValue: string) => {
                        setFieldValue(
                          'updateStatusWorkflowCaseStatus',
                          newValue,
                        );
                      }}
                    />
                    <Box pb={2} />
                  </>
                )}
                {values.workflowAutomationActionTypeID === 'complete_workflow_stage_task' && (
                  <Field
                    as={AutocompleteInput}
                    required
                    label="Workflow stage task"
                    id="completeWorkflowStageTaskID"
                    name="completeWorkflowStageTaskID"
                    fullWidth
                    placeholder="Select"
                    options={
                      workflowStages?.flatMap((stage) => stage.stageTasks) || []
                    }
                    loading={loaders.loading}
                    groupBy={((option: any) => option?.title.toUpperCase())}
                    keySelector={(option: AutocompleteOption) => option.value}
                    labelSelector={(option: AutocompleteOption) => option.label}
                    TextFieldProps={{
                      error:
                        touched.completeWorkflowStageTaskID
                        && !!errors.completeWorkflowStageTaskID,
                      helperText: (
                        <ErrorMessage name="completeWorkflowStageTaskID" />
                      ),
                    }}
                    onChange={(event: ChangeEvent<{}>, newValue: string) => {
                      setFieldValue(
                        'completeWorkflowStageTaskID',
                        newValue,
                      );
                    }}
                  />
                )}
                {values.workflowAutomationActionTypeID === 'complete_workflow_stage' && (
                  <Field
                    as={AutocompleteInput}
                    required
                    label="Workflow stage"
                    id="completeWorkflowStageID"
                    name="completeWorkflowStageID"
                    fullWidth
                    placeholder="Select"
                    options={workflowStages}
                    loading={loaders.loading}
                    keySelector={(option: AutocompleteOption) => option.value}
                    labelSelector={(option: AutocompleteOption) => option.label}
                    TextFieldProps={{
                      error:
                        touched.completeWorkflowStageID
                        && !!errors.completeWorkflowStageID,
                      helperText: (
                        <ErrorMessage name="completeWorkflowStageID" />
                      ),
                    }}
                    onChange={(event: ChangeEvent<{}>, newValue: string) => {
                      setFieldValue('completeWorkflowStageID', newValue);
                    }}
                  />
                )}
                <Box className={classes.ctaButton}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isValidating || isSubmitting || !isValid}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}
      </Formik>
    </>
  );
};

export default ConfigureCaseDrawer;
