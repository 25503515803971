import { makeStyles } from '@material-ui/core/styles';
import { tertiaryColors } from '../../../styles/variables';

export default makeStyles(() => ({
  variableTag: {
    fontSize: '0.875rem',
    display: 'inline-flex',
    borderRadius: '50px',
    padding: '0.2rem 1rem',
    backgroundColor: tertiaryColors.mediumGrey,
    '&.disabled': {
      backgroundColor: tertiaryColors.darkGrey,
    },
  },
}));
