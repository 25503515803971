import { useEffect, ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import { Close } from '@material-ui/icons';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from 'formik';
import { object, string, number } from 'yup';
import {
  AutocompleteInput,
  Drawer,
  TextFieldInput,
  TextFieldLabel,
} from '..';
import { ConfigureEmailDrawerProps } from './types';
import {
  AutomationFormAction,
  AutomationFormValues,
  ListingUser,
  TParams,
} from '../../types';
import { AutocompleteOption } from '../AutocompleteInput/types';
import ContentEditor from '../ContentEditor';
import {
  convertArrayToString,
  convertStringToArray,
  getErrorMessage,
} from '../../utils';
import { listAffiliates } from '../../apis';
import { useAlert, useAuth } from '../../hooks';
import { leadActions } from '../../pages/Workflow/Tabs/LeadAutomation/formData';
import { leadDefaultWorkflowID } from '../../constants';
import useStyles from './styles';

const ConfigureEmailDrawer = ({
  actions,
  open,
  onClose,
  selectedActionIndex,
  selectedSuggestion,
}: ConfigureEmailDrawerProps) => {
  const classes = useStyles();
  const { setAlert } = useAlert();
  const { user } = useAuth();

  const validationSchema = object({
    workflowAutomationActionTypeID: string().required('Action type is required'),
    sendEmailTemplate: string().required('Email text is required'),
    sendEmailSubject: string().required('Email subject is required'),
    sendEmailRecipients: string().required('CC is required'),
    ...(selectedSuggestion === 'reminder_email' ? {
      sendEmailReminderLimit: number()
        .required('Email attempts is required')
        .min(1, 'Min value 1')
        .max(3, 'Max value 3'),
    } : {}),
  });

  const isLeadDefaultWorkflow = (
    actions
    && selectedActionIndex !== null
    && actions[selectedActionIndex]
    && actions[selectedActionIndex].workflowID === leadDefaultWorkflowID
  );
  const maxEmailLength = 1000;
  const formikProps = useFormikContext<AutomationFormValues>();

  const { type } = useParams<TParams>();

  const [linkLabel, setLinkLabel] = useState('');
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const [recipients, setRecipients] = useState<ListingUser[]>([]);

  const handleGetEmailRecipients = async () => {
    try {
      // TODO: Support onScroll/Search to retrieve call after upgrade material to v5
      // Currently autocomplete have can't set inputValue issue
      const res = await listAffiliates(user.companyID, { limit: 1000 });
      if (res && res.items) {
        setRecipients(res.items);
      }
    } catch (err) {
      setAlert(
        {
          message: getErrorMessage(err),
          severity: 'error',
        },
        true,
      );
    }
  };

  useEffect(() => {
    if (!isLeadDefaultWorkflow) {
      handleGetEmailRecipients();
    }
  }, []);

  const getActionOptions = () => {
    let options: AutocompleteOption[] = [];
    if (type === 'lead') {
      if (selectedSuggestion) {
        const suggestionIndex = leadActions.findIndex((action) => (
          action.name === selectedSuggestion
        ));
        if (suggestionIndex > -1) {
          options = leadActions[suggestionIndex].types;
        }
      } else {
        options = [];
      }
    }
    return options;
  };

  // TODO add ability to render links in email
  const renderLinkDialog = () => (
    <Dialog open={isLinkDialogOpen} className={classes.dialogContainer}>
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Add link</Typography>
          <IconButton
            edge="end"
            onClick={() => setIsLinkDialogOpen(false)}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="textSecondary" variant="body2">
          Add a link to your Email Paste the URL below. Customise the middle
          section of your link
        </DialogContentText>
        <Box mb={2.5}>
          <TextFieldInput
            label="Paste URL"
            variant="outlined"
            fullWidth
            multiline
            minRows={2}
            maxRows={2}
          />
        </Box>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.wordCount}
        >
          {`${linkLabel.length}/20 characters`}
        </Typography>
        <TextFieldInput
          label="Customise label"
          variant="outlined"
          fullWidth
          value={linkLabel}
          inputProps={{ maxLength: 20 }}
          onChange={(e) => {
            setLinkLabel(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Box m={1.5} mr={0} pr={1.5}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsLinkDialogOpen(false)}
          >
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );

  const renderConfigurationFields = (formik: FormikProps<AutomationFormAction>) => {
    const {
      values,
      touched,
      errors,
      setFieldValue,
    } = formik;
    if (values.workflowAutomationActionTypeID) {
      switch (values.workflowAutomationActionTypeID) {
        case 'default_notify_lead_email':
        case 'default_notify_opportunity_email':
        case 'notify_lead_email':
        case 'notify_opportunity_email':
        case 'remind_lead_email':
        case 'remind_opportunity_email':
          return (
            <>
              <Box mb={1.5}>
                <TextFieldInput
                  label="Trigger email"
                  variant="outlined"
                  fullWidth
                  disabled
                  required
                  value={values?.sendEmailOn === '* * * * *' ? 'Immediate' : values.sendEmailOn}
                />
              </Box>
              {
                ['remind_lead_email', 'remind_opportunity_email'].includes(values.workflowAutomationActionTypeID)
                  && (
                    <>
                      <Box mb={1.5}>
                        <Field
                          as={TextFieldInput}
                          required
                          fullWidth
                          type="number"
                          variant="outlined"
                          id="sendEmailReminderLimit"
                          name="sendEmailReminderLimit"
                          label="Email attempts"
                          infoText="Number of email attempts are limited to 3 only regardless of the number of assignees that have been assigned"
                          value={values.sendEmailReminderLimit}
                          InputProps={{ inputProps: { min: 1, max: 3 } }}
                          error={touched.sendEmailReminderLimit && !!errors.sendEmailReminderLimit}
                          helperText={<ErrorMessage name="sendEmailReminderLimit" />}
                        />
                      </Box>
                      <Box mb={1.5}>
                        <Field
                          as={TextFieldInput}
                          required
                          fullWidth
                          disabled
                          variant="outlined"
                          label="Send email to"
                          id="fixedEmailRecipients"
                          name="fixedEmailRecipients"
                          value="Assignee"
                        />
                      </Box>
                    </>
                  )
              }
              <Box mb={1.5}>
                {
                  isLeadDefaultWorkflow
                    ? (
                      <Field
                        as={TextFieldInput}
                        required
                        fullWidth
                        disabled
                        variant="outlined"
                        label="Send email to"
                        id="sendEmailRecipients"
                        name="sendEmailRecipients"
                        value={values.sendEmailRecipients}
                      />
                    )
                    : (
                      <Field
                        as={AutocompleteInput}
                        required
                        label="CC"
                        id="sendEmailRecipients"
                        name="sendEmailRecipients"
                        fullWidth
                        multiple
                        placeholder="Select"
                        value={convertStringToArray(values.sendEmailRecipients)}
                        options={recipients}
                        keySelector={(option: ListingUser) => option.id}
                        labelSelector={(option: ListingUser) => option.email}
                        onChange={(e: ChangeEvent<{}>, newValue: string[]) => {
                          setFieldValue('sendEmailRecipients', convertArrayToString(newValue));
                        }}
                        TextFieldProps={{
                          error: (
                            touched.sendEmailRecipients
                            && !!errors.sendEmailRecipients
                          ),
                          helperText: <ErrorMessage name="sendEmailRecipients" />,
                        }}
                        renderOption={(option: ListingUser) => (
                          <div>
                            <Typography>
                              { option.name }
                            </Typography>
                            <Typography variant="body2">
                              { option.email }
                            </Typography>
                          </div>
                        )}
                      />
                    )
                }
              </Box>
              <Box mb={1.5}>
                <Field
                  as={TextFieldInput}
                  required
                  fullWidth
                  variant="outlined"
                  label="Email subject"
                  name="sendEmailSubject"
                  disabled={isLeadDefaultWorkflow}
                  value={values.sendEmailSubject}
                  error={touched.sendEmailSubject && !!errors.sendEmailSubject}
                  helperText={<ErrorMessage name="sendEmailSubject" />}
                />
              </Box>
              <Field
                as={ContentEditor}
                label="Email text"
                name="sendEmailTemplate"
                required
                variant="outlined"
                defaultContents={values.sendEmailTemplate}
                disabled={isLeadDefaultWorkflow}
                hideButtons={isLeadDefaultWorkflow}
                error={touched.sendEmailTemplate && !!errors.sendEmailTemplate}
                maxLength={maxEmailLength}
                onChange={(newValue: string) => {
                  setFieldValue('sendEmailTemplate', newValue);
                }}
              />
              <FormHelperText error>
                <ErrorMessage name="sendEmailTemplate" />
              </FormHelperText>
              {
                ['remind_lead_email', 'remind_opportunity_email'].includes(values.workflowAutomationActionTypeID)
                && (
                  <Box mt={2}>
                    <TextFieldLabel required>Schedule email hours</TextFieldLabel>
                    <Typography variant="body2">Select the hours that your sales team will be emailed</Typography>
                    {
                      ['Weekday', 'Weekend'].map((dayType) => (
                        <Box mt={1.5} className={classes.scheduleBox} key={dayType}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <FormControlLabel
                              disabled
                              control={<Checkbox defaultChecked color="secondary" />}
                              label={dayType}
                            />
                            <Typography variant="subtitle2" className={classes.greyText}>12hrs</Typography>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                          >
                            <Grid item>
                              <TimePicker
                                inputVariant="outlined"
                                disabled
                                value={moment('8:00AM', 'HH:mmA')}
                                onChange={() => {}}
                              />
                            </Grid>
                            <Typography color="textSecondary">to</Typography>
                            <Grid item>
                              <TimePicker
                                inputVariant="outlined"
                                disabled
                                value={moment('8:00PM', 'HH:mmA')}
                                onChange={() => {}}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ))
                    }
                  </Box>
                )
              }
            </>
          );
        default:
          return null;
      }
    }
    return null;
  };

  const getFilterOptions = (
    options: AutocompleteOption[],
  ) => {
    // Filter out options that selected by own and other action fields
    let filteredOptions = options;
    if (actions?.length) {
      const selectedOptions: string[] = [];
      actions.forEach((action, index) => {
        if (index !== selectedActionIndex && action.workflowAutomationActionTypeID) {
          selectedOptions.push(action.workflowAutomationActionTypeID);
        }
      });
      filteredOptions = options.filter((option) => !selectedOptions.includes(option.value));
    }
    return filteredOptions;
  };

  return (
    <Formik
      initialValues={formikProps.values.actions[selectedActionIndex as number]}
      onSubmit={(values) => {
        formikProps.setFieldValue(
          `actions.${selectedActionIndex}`,
          values,
        );
        formikProps.setFieldValue(
          `actions.${selectedActionIndex}.workflowAutomationActionTypeID`,
          values.workflowAutomationActionTypeID,
        );
        onClose();
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formik) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          isValidating,
          isSubmitting,
          isValid,
        } = formik;
        return (
          <>
            {renderLinkDialog()}
            <Drawer title="Configure action" open={open} onClose={onClose}>
              <Box
                component={Form}
                p={2.5}
                height="100%"
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Box className={classes.container}>
                  <Box mb={1.5}>
                    <Field
                      as={AutocompleteInput}
                      required
                      label="Action type"
                      id="workflowAutomationActionTypeID"
                      name="workflowAutomationActionTypeID"
                      fullWidth
                      placeholder="Select"
                      disabled={isLeadDefaultWorkflow}
                      filterOptions={
                        (options: AutocompleteOption[]) => getFilterOptions(
                          options,
                        )
                      }
                      options={getActionOptions()}
                      keySelector={(option: AutocompleteOption) => option.value}
                      labelSelector={(option: AutocompleteOption) => option.label}
                      TextFieldProps={{
                        error: (
                          touched.workflowAutomationActionTypeID
                          && !!errors.workflowAutomationActionTypeID
                        ),
                        helperText: <ErrorMessage name="workflowAutomationActionTypeID" />,
                      }}
                      onChange={(event: ChangeEvent<{}>, newValue: string) => {
                        setFieldValue('workflowAutomationActionTypeID', newValue);
                      }}
                    />
                  </Box>
                  {
                    values.workflowAutomationActionTypeID
                    && renderConfigurationFields(formik)
                  }
                </Box>
                <Box className={classes.ctaButton}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isLeadDefaultWorkflow || isValidating || isSubmitting || !isValid}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Drawer>
          </>
        );
      }}
    </Formik>
  );
};

export default ConfigureEmailDrawer;
