import {
  useState,
} from 'react';
import {
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  DropdownMenuProps,
  OptionProps,
} from './types';
import useStyles from './styles';

const DropdownMenu = (
  props: DropdownMenuProps,
) => {
  const {
    anchorOrigin,
    transformOrigin,
    children,
    options,
    headerNode,
    backdrop,
    onOpenCallback,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (
    event: any,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (onOpenCallback) {
      onOpenCallback();
    }
  };

  const handleClose = (
    event: any,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickOption = (
    event: any,
    option: OptionProps,
  ) => {
    option.onClick(event);
    handleClose(event);
  };

  const renderMenuItem = (
    option: OptionProps,
  ) => {
    if (option.hide) {
      return null;
    }
    return (
      <MenuItem
        key={option.name}
        disabled={option.isHeader || option.disabled}
        className={option.isHeader ? classes.header : classes.item}
        onClick={(e) => handleClickOption(e, option)}
      >
        { option.children }
      </MenuItem>
    );
  };

  return (
    <>
      {typeof children === 'function' ? children({ isOpen: Boolean(anchorEl), open: handleOpen, close: handleClose }) : children}
      <Menu
        keepMounted
        disableAutoFocusItem
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        MenuListProps={{ disablePadding: true }}
        className={backdrop ? classes.backdrop : ''}
      >
        { headerNode }
        {
          options.map((option: OptionProps) => (
            renderMenuItem(option)
          ))
        }
      </Menu>
    </>
  );
};

DropdownMenu.defaultProps = {
  backdrop: false,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
  headerNode: null,
};

export default DropdownMenu;
