import {
  Avatar,
  Chip,
} from '@material-ui/core';
import { ChipNameProps } from './types';
import { useStyles } from './styles';

const ChipName = (
  props: ChipNameProps,
) => {
  const {
    inactive,
    isSmallerFont,
    ...MuiChipProps
  } = props;
  const classes = useStyles(props, isSmallerFont, inactive);

  const renderNameInitial = () => {
    if (MuiChipProps.label && typeof MuiChipProps.label === 'string') {
      const { label } = MuiChipProps;
      const initial: string = (label.charAt(0)).toUpperCase();
      return initial;
    }
    return '';
  };

  return (
    <Chip
      avatar={<Avatar>{ renderNameInitial() }</Avatar>}
      classes={{
        root: classes.root,
        ...MuiChipProps.classes,
      }}
      {...MuiChipProps}
    />
  );
};

export default ChipName;
