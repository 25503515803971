import { useCallback, useState } from 'react';

// TODO: deprecate and use useToggleState instead

export function useLoader() {
  const [loaders, setLoaders] = useState({
    initialLoad: true,
    loading: false,
  });

  const toggleLoadingState = useCallback((name, bool) => {
    setLoaders((s) => ({
      ...s,
      [name]: bool,
    }));
  }, []);

  return {
    loaders,
    setLoaders,
    toggleLoadingState,
  };
}
