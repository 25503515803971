import {
  Box,
  TextField as MuiTextField,
  Tooltip,
} from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import { TextFieldLabel } from '..';
import { TextFieldProps } from './types';
import useStyles from './styles';

const TextFieldInput = ({
  children,
  label,
  infoText,
  required = false,
  TextFieldLabelProps,
  InputProps,
  ...MuiTextFieldProps
}: TextFieldProps) => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <Box mb={0.5} display="flex" alignItems="center">
          <TextFieldLabel required={required} gutterBottom={false} {...TextFieldLabelProps}>
            {label}
          </TextFieldLabel>
          {
            infoText && (
              <Tooltip title={infoText}>
                <InfoOutlinedIcon className={classes.infoIcon} />
              </Tooltip>
            )
          }
        </Box>
      )}
      <MuiTextField
        InputProps={{
          ...InputProps,
        }}
        {...MuiTextFieldProps}
      >
        {children}
      </MuiTextField>
    </>
  );
};

export default TextFieldInput;
