import
{
  Box, Button, Typography,
} from '@material-ui/core';
import { RawDraftContentState } from 'draft-js';
import
{
  ErrorMessage,
  Field, FieldProps, Form, Formik,
} from 'formik';
import _ from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { object, string } from 'yup';
import
{
  ConfirmationModal, ContentEditor, Drawer, TextFieldInput,
} from '..';
import { listAffiliates } from '../../apis';
import { ALL_ACCESS_GROUPS } from '../../constants';
import { useAlert, useAuth, useToggleState } from '../../hooks';
import { creditControlActions } from '../../pages/NewAutomation/formData';
import
{
  AccessGroup, ListingUser, TParams,
} from '../../types';
import { convertArrayToString, convertStringToArray, getErrorMessage } from '../../utils';
import AutocompleteInput from '../AutocompleteInput';
import { AutocompleteOption } from '../AutocompleteInput/types';
import useStyles from './styles';
import { ConfigureNotificationProps } from './types';

const ConfigureNotificationDrawer = ({
  actionTypeID,
  formikProps,
  open,
  onClose,
  selectedActionIndex,
  selectedSuggestion,
  setActionTypeID,
}: ConfigureNotificationProps) => {
  const { type } = useParams<TParams>();
  const { user } = useAuth();
  const classes = useStyles();
  const { setAlert } = useAlert();
  const { activeStates, toggleState } = useToggleState({
    leaveConfirmation: false,
  });
  const [recipients, setRecipients] = useState<ListingUser[]>([]);
  const maxEmailLength = 1000;

  const getTitle = () => creditControlActions[0].types.find(
    (actionType) => actionType.value === actionTypeID,
  )?.label || 'N/A';

  const getActionOptions = () => {
    let options: AutocompleteOption[] = [];
    if (type === 'credit_control') {
      if (selectedSuggestion) {
        const suggestionIndex = creditControlActions.findIndex(
          (action) => action.name === selectedSuggestion,
        );
        if (suggestionIndex > -1) {
          options = _.filter(
            creditControlActions[suggestionIndex].types,
            (opt) => opt.value === 'send_email',
          );
        }
      } else {
        options = [];
      }
    }
    return options;
  };

  const handleGetEmailRecipients = async () => {
    try {
      // TODO: Support onScroll/Search to retrieve call after upgrade material to v5
      // Currently autocomplete have can't set inputValue issue
      const res = await listAffiliates(user.companyID, { limit: 1000 });
      if (res && res.items) {
        setRecipients(res.items);
      }
    } catch (err) {
      setAlert(
        {
          message: getErrorMessage(err),
          severity: 'error',
        },
        true,
      );
    }
  };

  useEffect(() => {
    handleGetEmailRecipients();
  }, []);

  const renderModal = () => (
    <ConfirmationModal
      open={activeStates.leaveConfirmation}
      onConfirm={onClose}
      onCancel={() => {
        toggleState('leaveConfirmation');
      }}
      type="leave"
    />
  );

  const getDefaultContents = (contentState: RawDraftContentState | undefined) => {
    if (contentState && Object.keys(contentState).length !== 0
    ) {
      return contentState;
    }

    return { blocks: [], entityMap: {} };
  };

  return (
    <>
      {renderModal()}
      <Formik
        initialValues={{
          ...formikProps.values.actions[selectedActionIndex as number],
          workflowAutomationActionTypeID: actionTypeID,
        }}
        onSubmit={(values) => {
          const workflowValues = [
            'workflowID',
            'workflowTypeID',
            'workflowAutomationID',
            'workflowAutomationActionTypeID',
          ];

          const sendEmailValues = _.pick(
            values,
            'sendEmailTemplate',
            'sendEmailSubject',
            'sendEmailRecipients',
            ...workflowValues,
          );

          const sendInAppNotificationValues = _.pick(
            values,
            'sendInAppNotificationUserIDs',
            'sendInAppNotificationUserGroups',
            'sendInAppNotificationSetting',
            ...workflowValues,
          );

          let sendInAppNotificationSettingValues = _.pick(
            sendInAppNotificationValues.sendInAppNotificationSetting,
            'titleTemplate',
            'titleTemplateContentState',
            'messageTemplate',
            'messageTemplateContentState',
          );

          if (sendInAppNotificationValues?.sendInAppNotificationSetting?.id) {
            sendInAppNotificationSettingValues = {
              ...sendInAppNotificationSettingValues,
              id: sendInAppNotificationValues.sendInAppNotificationSetting.id,
            };
          }

          switch (values.workflowAutomationActionTypeID) {
            case 'send_email':
              formikProps.setFieldValue(`actions.${selectedActionIndex}`, {
                ...sendEmailValues,
                sendEmailOn: '* * * * *',
              });
              break;
            case 'send_inapp_notification': {
              formikProps.setFieldValue(`actions.${selectedActionIndex}`, {
                ...sendInAppNotificationValues,
                sendInAppNotificationSetting: {
                  ...sendInAppNotificationSettingValues,
                  appID: 'credit_control',
                },
              });
              break;
            }
            default:
          }

          if (values.id) {
            formikProps.setFieldValue(
              `actions.${selectedActionIndex}.id`,
              values.id,
            );
          }

          onClose();
        }}
        validationSchema={object({
          workflowAutomationActionTypeID: string().required(
            'Action type is required',
          ),
          sendEmailTemplate: string().when('workflowAutomationActionTypeID', {
            is: 'send_email',
            then: (schema) => schema.required('Email text is required'),
          }),
          sendEmailSubject: string().when('workflowAutomationActionTypeID', {
            is: 'send_email',
            then: (schema) => schema.required('Email subject is required'),
          }),
          sendEmailRecipients: string().when('workflowAutomationActionTypeID', {
            is: 'send_email',
            then: (schema) => schema.required('Send email to is required'),
          }),
          sendInAppNotificationUserIDs: string().when(
            'workflowAutomationActionTypeID',
            {
              is: 'send_inapp_notification',
              then: (schema) => schema.required('Send in-app notification to is required'),
            },
          ),
          sendInAppNotificationUserGroups: string().when(
            'workflowAutomationActionTypeID',
            {
              is: 'send_inapp_notification',
              then: (schema) => schema.required('User groups is required'),
            },
          ),
          sendInAppNotificationSetting: object().when(
            'workflowAutomationActionTypeID',
            {
              is: 'send_inapp_notification',
              then: object().shape({
                titleTemplate: string().required('Title template is required'),
                messageTemplate: string().required(
                  'Message template is required',
                ),
              }),
            },
          ),
        })}
        validateOnMount
      >
        {({
          values,
          touched,
          errors,
          setFieldTouched,
          setFieldValue,
          dirty,
          isValidating,
          isSubmitting,
          isValid,
          resetForm,
        }) => (
          <Drawer
            title={getTitle()}
            open={open}
            onClose={() => (dirty ? toggleState('leaveConfirmation') : onClose())}
          >
            <Box
              component={Form}
              p={2.5}
              height="100%"
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Box className={classes.container}>
                <Field
                  as={AutocompleteInput}
                  required
                  label="Action type"
                  id="workflowAutomationActionTypeID"
                  name="workflowAutomationActionTypeID"
                  fullWidth
                  placeholder="Select"
                  disableClearable
                  disabled={!!values.id}
                  options={getActionOptions()}
                  keySelector={(option: AutocompleteOption) => option.value}
                  labelSelector={(option: AutocompleteOption) => option.label}
                  TextFieldProps={{
                    error:
                      touched.workflowAutomationActionTypeID
                      && !!errors.workflowAutomationActionTypeID,
                    helperText: (
                      <ErrorMessage name="workflowAutomationActionTypeID" />
                    ),
                  }}
                  value={values.workflowAutomationActionTypeID}
                  onChange={(event: ChangeEvent<{}>, newValue: string) => {
                    setActionTypeID(newValue);
                    resetForm();
                    setFieldValue('workflowAutomationActionTypeID', newValue);
                  }}
                />
                <Box pb={2} />
                {values.workflowAutomationActionTypeID === 'send_email' && (
                  <Field
                    as={AutocompleteInput}
                    required
                    label="Send email to"
                    id="sendEmailRecipients"
                    name="sendEmailRecipients"
                    fullWidth
                    multiple
                    placeholder="Select"
                    value={convertStringToArray(values.sendEmailRecipients)}
                    options={recipients}
                    keySelector={(option: ListingUser) => option.id}
                    labelSelector={(option: ListingUser) => option.email}
                    onChange={(e: ChangeEvent<{}>, newValue: string[]) => {
                      setFieldValue(
                        'sendEmailRecipients',
                        convertArrayToString(newValue),
                      );
                    }}
                    TextFieldProps={{
                      error:
                        touched.sendEmailRecipients
                        && !!errors.sendEmailRecipients,
                      helperText: <ErrorMessage name="sendEmailRecipients" />,
                    }}
                    renderOption={(option: ListingUser) => (
                      <div>
                        <Typography>{option.name}</Typography>
                        <Typography variant="body2">{option.email}</Typography>
                      </div>
                    )}
                  />
                )}
                {values.workflowAutomationActionTypeID
                  === 'send_inapp_notification' && (
                  <>
                    <Field
                      as={AutocompleteInput}
                      required
                      label="Send in-app notification to"
                      id="sendInAppNotificationUserIDs"
                      name="sendInAppNotificationUserIDs"
                      fullWidth
                      multiple
                      placeholder="Select"
                      value={convertStringToArray(
                        values.sendInAppNotificationUserIDs,
                      )}
                      options={recipients}
                      keySelector={(option: ListingUser) => option.id}
                      labelSelector={(option: ListingUser) => option.email}
                      onChange={(e: ChangeEvent<{}>, newValue: string[]) => {
                        setFieldValue(
                          'sendInAppNotificationUserIDs',
                          convertArrayToString(newValue),
                        );
                      }}
                      TextFieldProps={{
                        error:
                          touched.sendInAppNotificationUserIDs
                          && !!errors.sendInAppNotificationUserIDs,
                        helperText: (
                          <ErrorMessage name="sendInAppNotificationUserIDs" />
                        ),
                      }}
                      renderOption={(option: ListingUser) => (
                        <div>
                          <Typography>{option.name}</Typography>
                          <Typography variant="body2">
                            {option.email}
                          </Typography>
                        </div>
                      )}
                    />
                    <Box pb={2} />
                    <Field
                      as={AutocompleteInput}
                      required
                      label="User groups"
                      id="sendInAppNotificationUserGroups"
                      name="sendInAppNotificationUserGroups"
                      fullWidth
                      multiple
                      placeholder="Select"
                      value={convertStringToArray(
                        values.sendInAppNotificationUserGroups,
                      )}
                      options={ALL_ACCESS_GROUPS.map((group) => ({
                        id: group,
                      }))}
                      keySelector={(option: AccessGroup) => option.id}
                      labelSelector={(option: AccessGroup) => _.capitalize(option.id.split('_').join(' '))}
                      onChange={(e: ChangeEvent<{}>, newValue: string[]) => {
                        setFieldValue(
                          'sendInAppNotificationUserGroups',
                          convertArrayToString(newValue),
                        );
                      }}
                      TextFieldProps={{
                        error:
                          touched.sendInAppNotificationUserGroups
                          && !!errors.sendInAppNotificationUserGroups,
                        helperText: (
                          <ErrorMessage name="sendInAppNotificationUserGroups" />
                        ),
                      }}
                    />
                    <Box pb={2} />
                    <Field name="sendInAppNotificationSetting.titleTemplate">
                      {({ field, meta }: FieldProps) => (
                        <TextFieldInput
                          required
                          fullWidth
                          variant="outlined"
                          label="Notification title"
                          error={meta.touched && !!meta.error}
                          helperText={<ErrorMessage name={field.name} />}
                          {...field}
                          value={meta.value || ''}
                        />
                      )}
                    </Field>
                    <Box pb={2} />
                    <Field name="sendInAppNotificationSetting.messageTemplate">
                      {({ field, meta }: FieldProps) => (
                        <ContentEditor
                          label="Notification text"
                          required
                          defaultContents={getDefaultContents(values.sendInAppNotificationSetting
                            ?.messageTemplateContentState)}
                          error={
                            !meta.value && meta.touched && !!meta.error
                              ? meta.error
                              : ''
                          }
                          maxLength={maxEmailLength}
                          {...field}
                          onChange={(
                            htmlContentState: string,
                            rawContentState = {} as RawDraftContentState,
                          ) => {
                            setFieldValue(
                              'sendInAppNotificationSetting.messageTemplate',
                              htmlContentState,
                            );
                            setFieldValue(
                              'sendInAppNotificationSetting.messageTemplateContentState',
                              rawContentState,
                            );
                          }}
                          handleEditorTouched={() => setFieldTouched(field.name)}
                        />
                      )}
                    </Field>
                  </>
                )}
                <Box pb={2} />
                {values.workflowAutomationActionTypeID === 'send_email' && (
                  <>
                    <Field
                      as={TextFieldInput}
                      required
                      fullWidth
                      variant="outlined"
                      label="Email subject"
                      name="sendEmailSubject"
                      value={values.sendEmailSubject || ''}
                      error={
                        touched.sendEmailSubject && !!errors.sendEmailSubject
                      }
                      helperText={<ErrorMessage name="sendEmailSubject" />}
                    />
                    <Box pb={2} />
                    <Field name="sendEmailTemplate">
                      {({ field, meta }: FieldProps) => (
                        <ContentEditor
                          label="Email text"
                          required
                          defaultContents={meta.value || ''}
                          error={
                            !meta.value && meta.touched && !!meta.error
                              ? meta.error
                              : ''
                          }
                          maxLength={maxEmailLength}
                          {...field}
                          onChange={(newValue: string) => {
                            setFieldValue('sendEmailTemplate', newValue);
                          }}
                          handleEditorTouched={() => setFieldTouched(field.name)}
                        />
                      )}
                    </Field>
                  </>
                )}
                <Box pb={2} />
                <Box className={classes.ctaButton}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isValidating || isSubmitting || !isValid}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}
      </Formik>
    </>
  );
};

export default ConfigureNotificationDrawer;
