import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  primaryColors,
  fontColors,
  tertiaryColors,
} from '../../styles/variables';

export default makeStyles((theme) => ({
  drawer__paperAnchorRight: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '395px',
    },
  },
  sidePadding: {
    padding: theme.spacing(0, 3),
  },
  header: {
    backgroundColor: primaryColors.contrastText,
    borderBottom: `1px solid ${tertiaryColors.grey}`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  closeIcon: {
    color: fontColors.body,
  },
  drawer__iconButton: {
    marginRight: theme.spacing(2),
  },
}));
