import {
  array, object, string,
} from 'yup';
import { callActionObjectValidationSchema } from '../../../../components/ConfigureCallDrawer/formData';
import {
  AutomationFormAction,
  AutomationFormCondition,
  AutomationFormValues,
  AutomationSuggestionType,
  DropdownOption,
  WorkflowAutomationCondition,
} from '../../../../types';
import { AnswerpointAutocompleteOption, CampaignAutocompleteOption } from './types';

export const leadActions = [
  {
    name: 'default_automation',
    types: [
      { label: 'Email assignee when lead is assigned', value: 'default_notify_lead_email' },
      { label: 'Email assignee when opportunity is assigned', value: 'default_notify_opportunity_email' },
    ],
  },
  {
    name: 'assignment_email',
    types: [
      { label: 'Email others on new lead', value: 'notify_lead_email' },
      { label: 'Email others on new opportunity', value: 'notify_opportunity_email' },
    ],
  },
  {
    name: 'reminder_email',
    types: [
      { label: 'Email reminder when lead is inactive', value: 'remind_lead_email' },
      { label: 'Email reminder when opportunity is inactive', value: 'remind_opportunity_email' },
    ],
  },
  {
    name: 'call',
    types: [
      { label: 'Call assignee then lead from submission', value: 'notify_lead_call' },
      { label: 'Call assignee then customer with opportunity from submission', value: 'notify_customer_call' },
    ],
  },
  {
    name: 'merge_duplicate_records',
    types: [
      { label: 'Merge duplicated leads with existing leads', value: 'merge_duplicate_leads' },
      { label: 'Merge duplicated leads to existing customer with new opportunities', value: 'merge_duplicate_leads_to_customer' },
    ],
  },
];

export const initialConditionInput: AutomationFormCondition = {
  workflowTypeID: '',
  workflowID: '',
  workflowAutomationID: '',
  subject: '',
  field: '',
  operator: '',
  value: '',
  valueType: '',
};

export const initialEmaillActionInput: AutomationFormAction = {
  workflowTypeID: '',
  workflowID: '',
  workflowAutomationID: '',
  workflowAutomationActionTypeID: '',
  sendEmailOn: '* * * * *',
  sendEmailSubject: '',
  sendEmailRecipients: '',
  sendEmailTemplate: '',
};

export const initialCallActionInput: AutomationFormAction = {
  workflowTypeID: '',
  workflowID: '',
  workflowAutomationID: '',
  workflowAutomationActionTypeID: '',
  callLeadOn: '* * * * *',
  callLeadAssigneeAttempts: 3,
  callLeadAssigneeAttemptInterval: 10,
  callLeadAssigneeWeekdayDays: '',
  callLeadAssigneeWeekdayStartTime: '',
  callLeadAssigneeWeekdayEndTime: '',
  callLeadAssigneeWeekendDays: '',
  callLeadAssigneeWeekendStartTime: '',
  callLeadAssigneeWeekendEndTime: '',
  callLeadAssigneeCaller: 'assignee',
  callLeadAssigneeRecord: false,
};

export const initialMergeDuplicateActionInput: AutomationFormAction = {
  triggerMergeOn: '* * * * *',
};

export const initialValues: AutomationFormValues = {
  name: '',
  enabled: true,
  workflowTypeID: '',
  workflowID: '',
  rulesetLinking: 'AND',
  conditions: [initialConditionInput],
  actions: [],
};

export const notifyActionObjectValidationSchema = {
  workflowAutomationActionTypeID: string().required(),
  sendEmailOn: string().required(),
  sendEmailSubject: string().required(),
  sendEmailTemplate: string().required(),
};

export const getValidationSchema = (suggestionType: AutomationSuggestionType) => {
  switch (suggestionType) {
    case 'call':
      return callActionObjectValidationSchema;
    case 'assignment_email':
    case 'reminder_email':
      return notifyActionObjectValidationSchema;
    default:
      return { workflowAutomationActionTypeID: string().required() };
  }
};

export const validationSchema = (
  disabled: boolean,
  suggestionType: AutomationSuggestionType,
) => object().shape(
  disabled
    ? {}
    : {
      conditions: array()
        .of(
          object().shape({
            subject: string().required('Condition subject is required'),
            field: string().required('Condition field is required'),
            operator: string().required('Condition operator is required.'),
            value: string().required('Condition value is required.'),
          }),
        ),
      actions: array()
        .of(
          object().shape(
            getValidationSchema(suggestionType),
            [['callLeadAssigneeWeekdayDays', 'callLeadAssigneeWeekendDays']],
          ),
        ),
    },
);

export const fixedConditions = (
  automation: AutomationFormValues,
  workflowID: string,
  suggestionType?: string | null,
) => {
  let conditions:WorkflowAutomationCondition[] = [];
  switch (suggestionType) {
    case 'assignment_email':
      conditions = [
        {
          id: 'default-condition-1',
          workflowAutomationID: 'default-automation-id',
          workflowTypeID: automation.workflowTypeID,
          workflowID,
          subject: 'lead_record',
          field: 'created_at',
          operator: '==',
          value: 'now',
        },
      ];
      break;
    case 'reminder_email':
      conditions = [
        {
          id: 'default-condition-1',
          workflowAutomationID: 'default-automation-id',
          workflowTypeID: automation.workflowTypeID,
          workflowID,
          subject: 'lead_record',
          field: 'sales_activity',
          operator: '!=',
          value: 'updated',
        },
        {
          id: 'default-condition-2',
          workflowAutomationID: 'default-automation-id',
          workflowTypeID: automation.workflowTypeID,
          workflowID,
          subject: 'lead_record',
          field: 'assigned_to',
          operator: 'exists',
        },
      ];
      break;
    case 'call':
      conditions = [
        {
          id: 'default-condition-1',
          workflowAutomationID: 'default-automation-id',
          workflowTypeID: automation.workflowTypeID,
          workflowID,
          subject: 'lead_record',
          field: 'created_at',
          operator: '==',
          value: 'now',
        },
        {
          id: 'default-condition-3',
          workflowAutomationID: 'default-automation-id',
          workflowTypeID: automation.workflowTypeID,
          workflowID,
          subject: 'lead_record',
          field: 'mobile',
          operator: 'exists',
        },
        {
          id: 'default-condition-4',
          workflowAutomationID: 'default-automation-id',
          workflowTypeID: automation.workflowTypeID,
          workflowID,
          subject: 'lead_record',
          field: 'submission_source',
          operator: 'exists',
        },
      ];
      break;
    case 'merge_duplicate_records':
      conditions = [
        {
          id: 'default-condition-1',
          workflowAutomationID: 'default-automation-id',
          workflowTypeID: automation.workflowTypeID,
          workflowID,
          subject: 'lead_record',
          field: 'created_at',
          operator: '==',
          value: 'now',
        },
        {
          id: 'default-condition-2',
          workflowAutomationID: 'default-automation-id',
          workflowTypeID: automation.workflowTypeID,
          workflowID,
          subject: 'lead_record',
          field: 'submission_source',
          operator: 'exists',
        },
        {
          id: 'default-condition-3',
          workflowAutomationID: 'default-automation-id',
          workflowTypeID: automation.workflowTypeID,
          workflowID,
          subject: 'lead_record',
          field: 'mobile',
          operator: '==',
          value: 'exact_match',
        },
      ];
      break;
    default:
      break;
  }

  return conditions;
};

// Automation field condition options //

export const subjectTypeOptions = (type: string) => {
  let options:DropdownOption[] = [];

  switch (type) {
    case 'lead':
      options = [
        { label: 'Campaign field', value: 'lead_campaign' },
        { label: 'Project field', value: 'lead_project' },
        { label: 'Form field', value: 'lead_form' },
        { label: 'Sales field', value: 'lead_record' },
      ];
      break;
    default:
      break;
  }

  return options;
};

export const fieldTypeOptions = (
  fixed: boolean,
  type: string,
  suggestionType?: string | null,
  workflowTypeID?: string,
  subject?: string,
) => {
  let options:DropdownOption[] = [];

  if (subject) {
    switch (type) {
      case 'lead':
        switch (subject) {
          case 'lead_campaign':
          case 'lead_form':
            options = [
              { label: 'Name', value: 'id' },
            ];
            break;
          case 'lead_project':
            options = [
              { label: 'Name', value: 'ids' },
            ];
            break;
          case 'lead_record':
            if (workflowTypeID && ['lead_notification', 'lead_automation'].includes(workflowTypeID) && fixed) {
              options = [
                { label: 'Added on', value: 'created_at' },
                { label: 'Assigned on', value: 'assigned_at' },
                { label: 'Assignee', value: 'assigned_to' },
                { label: 'Imported on', value: 'imported_at' },
                { label: 'Mobile number', value: 'mobile' },
                { label: 'Sales activity', value: 'sales_activity' },
                { label: 'Submission source', value: 'submission_source' },
              ];
            } else if (suggestionType) {
              if (suggestionType === 'reminder_email') {
                options = [
                  { label: 'Last activity', value: 'last_activity' },
                  { label: 'Source', value: 'source' },
                ];
              } else if (suggestionType === 'call') {
                options = [
                  { label: 'Assignee with dialer', value: 'assignee_ids' },
                  { label: 'Source', value: 'source' },
                ];
              } else if (suggestionType === 'merge_duplicate_records') {
                options = [
                  { label: 'Email', value: 'email' },
                  { label: 'MyKad (NRIC)', value: 'nric' },
                  { label: 'Source', value: 'source' },
                  { label: 'Business unit', value: 'company_branch_id' },
                ];
              } else {
                options = [
                  { label: 'Source', value: 'source' },
                ];
              }
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  return options;
};

export const operatorTypeOptions = (
  type: string,
  field?: string,
  workflowTypeID?: string,
  suggestionType?: string | null,
) => {
  let options:DropdownOption[] = [];

  switch (type) {
    case 'lead':
      if (field) {
        if (workflowTypeID
          && workflowTypeID === 'lead_automation'
          && suggestionType
          && suggestionType === 'merge_duplicate_records') {
          switch (field) {
            case 'mobile':
            case 'email':
            case 'nric':
              options = [{ label: 'is', value: '==' }];
              break;
            case 'submission_source':
              options = [
                { label: 'exists', value: 'exists' },
                { label: 'does not exists', value: 'notexists' },
              ];
              break;
            default:
              options = [
                { label: 'is', value: '==' },
                { label: 'is not', value: '!=' },
              ];
              break;
          }
        } else {
          switch (field) {
            case 'last_activity':
              options = [
                { label: 'is greater than', value: '>' },
              ];
              break;
            case 'imported_at':
            case 'assigned_to':
            case 'mobile':
            case 'submission_source':
              options = [
                { label: 'exists', value: 'exists' },
                { label: 'does not exists', value: 'notexists' },
              ];
              break;
            default:
              options = [
                { label: 'is', value: '==' },
                { label: 'is not', value: '!=' },
              ];
              break;
          }
        }
      }
      break;
    default:
      break;
  }

  return options;
};

export const valueTypeOptions = (
  type: string,
  subject?: string,
  field?: string,
) => {
  let options:DropdownOption[]
  | CampaignAutocompleteOption[]
  | AnswerpointAutocompleteOption[] = [];

  if (subject && field) {
    switch (type) {
      case 'lead':
        switch (subject) {
          case 'lead_record':
            switch (field) {
              case 'assigned_at':
              case 'created_at':
                options = [
                  { label: 'now', value: 'now' },
                ];
                break;
              case 'sales_activity':
                options = [
                  { label: 'updated', value: 'updated' },
                ];
                break;
              case 'last_activity':
                options = [
                  { label: '30 minutes', value: '30m' },
                  { label: '2 hours', value: '2h' },
                  { label: '4 hours', value: '4h' },
                  { label: '12 hours', value: '12h' },
                  { label: '24 hours', value: '24h' },
                  { label: '48 hours', value: '48h' },
                ];
                break;
              case 'source':
                options = [
                  { label: 'Billboard/poster', value: 'billboard_poster' },
                  { label: 'Event', value: 'event' },
                  { label: 'Facebook', value: 'facebook' },
                  { label: 'Listing portals', value: 'listing_portals' },
                  { label: 'Instagram', value: 'instagram' },
                  { label: 'iProperty', value: 'iproperty' },
                  { label: 'Referral', value: 'referral' },
                  { label: 'Showroom', value: 'showroom' },
                  { label: 'Showroom for buyer', value: 'srb' },
                  { label: 'Walk-in', value: 'walk_in' },
                  { label: 'Website', value: 'website' },
                ];
                break;
              case 'mobile':
              case 'email':
              case 'nric':
              case 'company_branch_id':
                options = [{ label: 'exact match', value: 'exact_match' }];
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  return options;
};
