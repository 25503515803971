import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { AutomationFormValues } from '../../types';
import ConfigureCaseDrawer from '../ConfigureCaseDrawer';
import ConfigureNotificationDrawer from '../ConfigureNotificationDrawer';
import { ConfigureActionDrawerProps } from './types';

const ConfigureActionDrawer = ({
  initialActionTypeID = '',
  onClose,
  selectedActionIndex,
  selectedSuggestion,
}: ConfigureActionDrawerProps) => {
  const formikProps = useFormikContext<AutomationFormValues>();
  const [actionTypeID, setActionTypeID] = useState(initialActionTypeID);
  const [isConfigureCaseDrawerOpen, setIsConfigureCaseDrawerOpen] = useState(false);
  const [
    isConfigureNotificationDrawerOpen,
    setIsConfigureNotificationDrawerOpen,
  ] = useState(false);

  const isCaseAction = [
    'update_workflow_case_status',
    'complete_workflow_stage',
    'complete_workflow_stage_task',
  ].includes(actionTypeID);

  const isNotificationAction = [
    'send_email',
    'send_inapp_notification',
  ].includes(actionTypeID);

  useEffect(() => {
    setIsConfigureCaseDrawerOpen(isCaseAction);
    setIsConfigureNotificationDrawerOpen(isNotificationAction);
  }, [actionTypeID]);

  return (
    <>
      {isCaseAction && (
        <ConfigureCaseDrawer
          actionTypeID={actionTypeID}
          formikProps={formikProps}
          open={isConfigureCaseDrawerOpen}
          onClose={onClose}
          selectedActionIndex={selectedActionIndex}
          selectedSuggestion={selectedSuggestion}
          setActionTypeID={setActionTypeID}
        />
      )}
      {isNotificationAction && (
        <ConfigureNotificationDrawer
          actionTypeID={actionTypeID}
          formikProps={formikProps}
          open={isConfigureNotificationDrawerOpen}
          onClose={onClose}
          selectedActionIndex={selectedActionIndex}
          selectedSuggestion={selectedSuggestion}
          setActionTypeID={setActionTypeID}
        />
      )}
    </>
  );
};

export default ConfigureActionDrawer;
