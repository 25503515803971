import { object, string, number } from 'yup';
import { CompanyBranchOption } from '../../pages/Workflow/Tabs/LeadAutomation/types';

export const callActionObjectValidationSchema = {
  workflowAutomationActionTypeID: string().required('Action type is required'),
  callLeadAssigneeAttempts: number()
    .required('Call attempts is required')
    .min(1, 'Value cannot be less than one')
    .integer('Invalid format'),
  callLeadAssigneeAttemptInterval: number()
    .required('Call interval is required')
    .min(10, 'Value cannot be less than ten')
    .integer('Invalid format'),
  callLeadAssigneeWeekdayDays: string()
    .when('callLeadAssigneeWeekendDays', {
      is: (v: string) => !(v && v.length > 0),
      then: string().required('Require to check atleast one option'),
      otherwise: string(),
    }),
  callLeadAssigneeWeekdayStartTime: string()
    .when('callLeadAssigneeWeekdayDays', {
      is: (v: string) => v && v.length > 0,
      then: string().required('Start time is required'),
    }),
  callLeadAssigneeWeekdayEndTime: string()
    .when('callLeadAssigneeWeekdayDays', {
      is: (v: string) => v && v.length > 0,
      then: string().required('End time is required'),
    }),
  callLeadAssigneeWeekendDays: string()
    .when('callLeadAssigneeWeekdayDays', {
      is: (v: string) => !(v && v.length > 0),
      then: string().required('Require to check atleast one option'),
      otherwise: string(),
    }),
  callLeadAssigneeWeekendStartTime: string()
    .when('callLeadAssigneeWeekendDays', {
      is: (v: string) => v && v.length > 0,
      then: string().required('Start time is required'),
    }),
  callLeadAssigneeWeekendEndTime: string()
    .when('callLeadAssigneeWeekendDays', {
      is: (v: string) => v && v.length > 0,
      then: string().required('End time is required'),
    }),
};

export const validationSchema = (listBusinessUnit: CompanyBranchOption[]) => object().shape(
  {
    ...callActionObjectValidationSchema,
    callLeadAssigneeCaller: string()
      .test(
        'callLeadAssigneeCaller',
        'Mobile number is required',
        (value) => {
          if (value === 'assignee') return true;
          if (value) {
            const splitted = value.split('-');
            return (splitted.length > 1 && splitted[1] !== '') || false;
          }
          return false;
        },
      )
      .test(
        'callLeadAssigneeCaller',
        'The selected business unit’s number is invalid or has been deleted.',
        (value) => {
          if (value === 'assignee') return true;
          return (!!value
          && listBusinessUnit?.length > 0
          && listBusinessUnit.some((bu: CompanyBranchOption) => bu.id === value));
        },
      ),
  },
  [['callLeadAssigneeWeekdayDays', 'callLeadAssigneeWeekendDays']],
);
