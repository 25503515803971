import { makeStyles } from '@material-ui/core';
import { fontColors, fontVariants } from '../../styles/variables';

export default makeStyles((theme) => ({
  subLabel: {
    paddingLeft: 12,
  },
  root: {
    ...fontVariants.h6,
    textTransform: 'initial',
    paddingTop: 0,
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(4),
    minHeight: 0,
    minWidth: 0,
  },
  textColorPrimary: {
    color: fontColors.subBody,
  },
}));
