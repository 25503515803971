import React, {
  useState,
  useEffect,
} from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { SearchIcon } from '../../assets/icons';
import { SearchInputProps } from './types';
import useStyles from './styles';
import { TextFieldLabel } from '..';

const SearchInput = ({
  onSubmit,
  onClear,
  data,
  label,
  required = false,
  TextFieldLabelProps,
  ...rest
}: SearchInputProps) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState(data);

  useEffect(() => {
    if (data) {
      setInputValue(data);
    }
  }, [data]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputValue(e.target.value);
    onSubmit(e, e.target.value);
  };

  const handleOnClear = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setInputValue('');
    onClear(e);
  };

  const renderSearchIcon = (
    <InputAdornment
      className={classes.adornment}
      position="start"
    >
      <img alt="search" src={SearchIcon} className={classes.searchIcon} />
    </InputAdornment>
  );

  const renderDeleteIcon = () => {
    if (inputValue) {
      return (
        <InputAdornment
          className={classes.adornment}
          position="end"
        >
          <IconButton
            size="small"
            className={classes.clearIcon}
            onClick={handleOnClear}
          >
            <Close />
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  };

  return (
    <>
      {label && (
        <TextFieldLabel required={required} {...TextFieldLabelProps}>
          {label}
        </TextFieldLabel>
      )}
      <TextField
        classes={{
          root: classes.root,
        }}
        InputProps={{
          startAdornment: renderSearchIcon,
          endAdornment: renderDeleteIcon(),
        }}
        type="text"
        size="small"
        value={inputValue}
        onChange={handleOnChange}
        {...rest}
      />
    </>
  );
};

export default SearchInput;
