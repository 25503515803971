import { makeStyles } from '@material-ui/core/styles';
import { fontColors, fontVariants, tertiaryColors } from '../../styles/variables';

export default makeStyles((theme) => ({
  buttonContainer: {
    paddingLeft: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: `0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    borderTop: `1px solid ${tertiaryColors.mediumGrey}`,
  },
  editor: {
    width: '100%',
    height: '250px',
    overflow: 'auto',
    cursor: 'text',
    padding: '0.5rem 0.5rem 1rem 0.5rem',
    borderRadius: `0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderBottom: 'none',
    '& .DraftEditor-root': {
      ...fontVariants.body1,
    },
    '& .public-DraftEditorPlaceholder-root': {
      marginBottom: '-20px',
      color: tertiaryColors.grey,
    },
    '&.disabled': {
      color: fontColors.body,
      backgroundColor: tertiaryColors.lightGrey,
      border: 'none',
    },
  },
}));
