import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Icon,
  Link,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import { TimePicker } from '@material-ui/pickers';
import {
  Form,
  Formik,
  Field,
  useFormikContext,
  ErrorMessage,
  FormikProps,
} from 'formik';
import moment from 'moment';
import {
  AutocompleteInput,
  Banner,
  Drawer,
  TextFieldInput,
  TextFieldLabel,
} from '..';
import { CallWeekStartEndTimes, CallWeekTypes, ConfigureCallDrawerProps } from './types';
import { AutomationFormAction, AutomationFormValues, TParams } from '../../types';
import { AutocompleteOption } from '../AutocompleteInput/types';
import { leadActions } from '../../pages/Workflow/Tabs/LeadAutomation/formData';
import { TimeIcon } from '../../assets/icons';
import { validationSchema } from './formData';
import { CompanyBranchOption } from '../../pages/Workflow/Tabs/LeadAutomation/types';
import { useAuth } from '../../hooks';
import useStyles from './styles';

const ConfigureCallDrawer = ({
  listBusinessUnit,
  allListBusinessUnit,
  actions,
  open,
  onClose,
  selectedActionIndex,
  selectedSuggestion,
}: ConfigureCallDrawerProps) => {
  const classes = useStyles();
  const { user } = useAuth();

  const formikProps = useFormikContext<AutomationFormValues>();

  const { type } = useParams<TParams>();

  const [appendedListBusinessUnit, setAppendedListBusinessUnit] = useState(listBusinessUnit || []);

  useEffect(() => {
    if (actions?.length > 0) {
      const deletedBUNumber: CompanyBranchOption[] = [];
      // Append selected BU numbers that are deleted from the listing
      const selectedBUs = actions
        .filter((action) => action.callLeadAssigneeCaller && action.callLeadAssigneeCaller !== 'assignee')
        .map((action) => action.callLeadAssigneeCaller);
      if (selectedBUs.length > 0) {
        selectedBUs.forEach((id) => {
          const index = listBusinessUnit?.length
            ? listBusinessUnit.findIndex((item) => item.id === id)
            : -1;
          const isExists = index > -1;
          const buInfo = allListBusinessUnit?.length
            ? allListBusinessUnit.find((item) => item.id === id)
            : undefined;
          if (!isExists) {
            deletedBUNumber.push({
              id: id || '',
              name: buInfo?.name || 'Business unit deleted',
              is_external: 'false',
              msisdn: '',
              disabled: true,
            });
          }
        });
      }
      const newList = deletedBUNumber.concat(listBusinessUnit || []);
      setAppendedListBusinessUnit(newList);
    }
  }, [actions, listBusinessUnit]);

  const handleCheckScheduleDay = (
    e: ChangeEvent<HTMLInputElement>,
    setValues: any,
  ) => {
    const { name, checked } = e.target;
    // NOTE: 0 ~ 6 = Sun ~ Sat
    switch (name) {
      case 'callLeadAssigneeWeekdayDays':
        if (!checked) {
          setValues((prev: any) => ({
            ...prev,
            [name]: '',
            callLeadAssigneeWeekdayStartTime: '',
            callLeadAssigneeWeekdayEndTime: '',
          }));
        } else {
          setValues((prev: any) => ({
            ...prev,
            [name]: '1,2,3,4,5',
          }));
        }
        break;
      case 'callLeadAssigneeWeekendDays':
        if (!checked) {
          setValues((prev: any) => ({
            ...prev,
            [name]: '',
            callLeadAssigneeWeekendStartTime: '',
            callLeadAssigneeWeekendEndTime: '',
          }));
        } else {
          setValues((prev: any) => ({
            ...prev,
            [name]: '0,6',
          }));
        }
        break;
      default:
        break;
    }
  };

  const getFilterOptions = (
    options: AutocompleteOption[],
  ) => {
    // Filter out options that selected by own and other action fields
    let filteredOptions = options;
    if (actions?.length) {
      const selectedOptions: string[] = [];
      actions.forEach((action, index) => {
        if (index !== selectedActionIndex && action.workflowAutomationActionTypeID) {
          selectedOptions.push(action.workflowAutomationActionTypeID);
        }
      });
      filteredOptions = options.filter((option) => !selectedOptions.includes(option.value));
    }
    return filteredOptions;
  };

  const getActionOptions = () => {
    let options: AutocompleteOption[] = [];
    if (type === 'lead') {
      if (selectedSuggestion) {
        const suggestionIndex = leadActions.findIndex((action) => (
          action.name === selectedSuggestion
        ));
        if (suggestionIndex > -1) {
          options = leadActions[suggestionIndex].types;
        }
      } else {
        options = [];
      }
    }
    return options;
  };

  const getTimeDifferences = (end?: string, start?: string) => {
    if (end && start) {
      const end24 = moment(end, ['hh:mmA']).format('HH:mm');
      const start24 = moment(start, ['hh:mmA']).format('HH:mm');
      const hour = moment(moment(end24, 'HH:mm').diff(moment(start24, 'HH:mm'))).utcOffset(0).format('HH');
      const minute = moment(moment(end24, 'HH:mm').diff(moment(start24, 'HH:mm'))).utcOffset(0).format('mm');
      if (hour !== '00' || minute !== '00') {
        return `${hour}hr ${minute !== '00' ? `${minute} minutes` : ''}`;
      }
    }
    return '0hr';
  };

  const renderConfigurationFields = (formik: FormikProps<AutomationFormAction>) => {
    const {
      values,
      touched,
      errors,
      setFieldValue,
      setValues,
    } = formik;
    if (values.workflowAutomationActionTypeID) {
      switch (values.workflowAutomationActionTypeID) {
        case 'notify_lead_call':
        case 'notify_customer_call':
          return (
            <>
              <Box mb={1.5}>
                <TextFieldInput
                  required
                  label="Trigger call"
                  variant="outlined"
                  fullWidth
                  disabled
                  value="Immediately within calling hours"
                />
              </Box>
              <Box mb={1.5}>
                <TextFieldInput
                  disabled
                  required
                  fullWidth
                  variant="outlined"
                  label="Call attempts"
                  infoText="Number of call attempts that will be made to each unique assignee until they answer (eg. if reassignment is set up, we will call each assignee x no. of times)"
                  value="3 - 5"
                />
                {/* TODO: Temporary hides until backend allows user to key in */}
                {/* <Field
                  as={TextFieldInput}
                  required
                  label="Call attempts"
                  variant="outlined"
                  fullWidth
                  name="callLeadAssigneeAttempts"
                  type="number"
                  infoText="Number of call attempts that will be made to each unique assignee
                  // until they answer (eg. if reassignment is set up,
                  // we will call each assignee x no. of times)"
                  value={values.callLeadAssigneeAttempts}
                  error={touched.callLeadAssigneeAttempts && !!errors.callLeadAssigneeAttempts}
                  helperText={<ErrorMessage name="callLeadAssigneeAttempts" />}
                /> */}
              </Box>
              <Box mb={1.5}>
                <TextFieldInput
                  disabled
                  required
                  fullWidth
                  variant="outlined"
                  label="Call interval (seconds)"
                  infoText="Time between each call attempt"
                  value="17 - 67"
                />
                {/* TODO: Temporary hides until backend allows user to key in */}
                {/* <Field
                  as={TextFieldInput}
                  required
                  label="Call interval (minutes)"
                  variant="outlined"
                  fullWidth
                  name="callLeadAssigneeAttemptInterval"
                  type="number"
                  infoText="Time between each call attempt"
                  value={values.callLeadAssigneeAttemptInterval}
                  error={
                    touched.callLeadAssigneeAttemptInterval
                    && !!errors.callLeadAssigneeAttemptInterval
                  }
                  helperText={<ErrorMessage name="callLeadAssigneeAttemptInterval" />}
                /> */}
              </Box>

              <Box mb={1.5}>
                <Box mb={1}>
                  <TextFieldLabel required>
                    Schedule calling hours
                  </TextFieldLabel>
                  <Typography variant="body2">
                    Select the hours that your sales team will be called
                  </Typography>
                  {
                    (touched.callLeadAssigneeWeekdayDays && !!errors.callLeadAssigneeWeekdayDays)
                    || (touched.callLeadAssigneeWeekendDays && !!errors.callLeadAssigneeWeekendDays)
                      ? (
                        <FormHelperText error>
                          {
                            errors.callLeadAssigneeWeekdayDays
                            || errors.callLeadAssigneeWeekendDays
                          }
                        </FormHelperText>
                      ) : null
                  }
                </Box>
                {
                  ['Weekday', 'Weekend'].map((weekType) => (
                    <Box mb={1} className={classes.scheduleBox} key={weekType}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Field
                          as={FormControlLabel}
                          name={`callLeadAssignee${weekType}Days`}
                          control={(
                            <Checkbox
                              name={`callLeadAssignee${weekType}Days`}
                              checked={!!values?.[`callLeadAssignee${weekType}Days` as CallWeekTypes]}
                              onChange={(e) => handleCheckScheduleDay(e, setValues)}
                            />
                          )}
                          label={<Typography variant="subtitle1" color="textSecondary">{ weekType }</Typography>}
                        />
                        <Typography variant="subtitle2" color="textSecondary">
                          {
                            getTimeDifferences(
                              values?.[`callLeadAssignee${weekType}EndTime` as CallWeekStartEndTimes],
                              values?.[`callLeadAssignee${weekType}StartTime` as CallWeekStartEndTimes],
                            )
                          }
                        </Typography>
                      </Box>
                      <Box mt={0.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Field
                          as={TimePicker}
                          name={`callLeadAssignee${weekType}StartTime`}
                          emptyLabel="Select"
                          inputVariant="outlined"
                          disabled={!values?.[`callLeadAssignee${weekType}Days` as CallWeekTypes]}
                          InputProps={{
                            className: `${values?.[`callLeadAssignee${weekType}StartTime` as CallWeekStartEndTimes] ? '' : classes.placeholder}`,
                            endAdornment: (
                              values?.[`callLeadAssignee${weekType}Days` as CallWeekTypes]
                              && (
                                <Icon className={classes.timeIcon}>
                                  <img alt="time" src={TimeIcon} />
                                </Icon>
                              )
                            ),
                          }}
                          error={
                            touched[`callLeadAssignee${weekType}StartTime` as CallWeekStartEndTimes]
                            && !!errors[`callLeadAssignee${weekType}StartTime` as CallWeekStartEndTimes]
                          }
                          helperText={
                            touched[`callLeadAssignee${weekType}StartTime` as CallWeekStartEndTimes]
                            && errors[`callLeadAssignee${weekType}StartTime` as CallWeekStartEndTimes]
                          }
                          value={
                            values?.[`callLeadAssignee${weekType}StartTime` as CallWeekStartEndTimes]
                              ? moment(values[`callLeadAssignee${weekType}StartTime` as CallWeekStartEndTimes], 'hh:mmA')
                              : null
                          }
                          onChange={(v: any) => setFieldValue(
                            `callLeadAssignee${weekType}StartTime` as CallWeekStartEndTimes,
                            moment(v).format('hh:mmA'),
                          )}
                        />
                        <Box mr={1} ml={1}>
                          <Typography color="textSecondary">
                            to
                          </Typography>
                        </Box>
                        <Field
                          as={TimePicker}
                          name={`callLeadAssignee${weekType}EndTime`}
                          emptyLabel="Select"
                          inputVariant="outlined"
                          disabled={!values?.[`callLeadAssignee${weekType}Days` as CallWeekTypes]}
                          InputProps={{
                            className: `${values?.[`callLeadAssignee${weekType}EndTime` as CallWeekStartEndTimes] ? '' : classes.placeholder}`,
                            endAdornment: (
                              values?.[`callLeadAssignee${weekType}Days` as CallWeekTypes] && (
                                <Icon className={classes.timeIcon}>
                                  <img alt="time" src={TimeIcon} />
                                </Icon>
                              )
                            ),
                          }}
                          error={
                            touched[`callLeadAssignee${weekType}EndTime` as CallWeekStartEndTimes]
                            && !!errors[`callLeadAssignee${weekType}EndTime` as CallWeekStartEndTimes]
                          }
                          helperText={
                            touched[`callLeadAssignee${weekType}EndTime` as CallWeekStartEndTimes]
                            && errors[`callLeadAssignee${weekType}EndTime` as CallWeekStartEndTimes]
                          }
                          value={
                            values?.[`callLeadAssignee${weekType}EndTime` as CallWeekStartEndTimes]
                              ? moment(values[`callLeadAssignee${weekType}EndTime` as CallWeekStartEndTimes], 'hh:mmA')
                              : null
                          }
                          onChange={(v: any) => setFieldValue(
                            `callLeadAssignee${weekType}EndTime` as CallWeekStartEndTimes,
                            moment(v).format('hh:mmA'),
                          )}
                        />
                      </Box>
                    </Box>
                  ))
                }
              </Box>
              <Box mb={1.5}>
                <Field
                  as={AutocompleteInput}
                  required
                  fullWidth
                  disabled
                  label="Calling number to assignee"
                  id="callNumberToAssignee"
                  name="callNumberToAssignee"
                  placeholder="Select"
                  value="lead_mobile_number"
                  options={[{ label: 'Lead mobile number', value: 'lead_mobile_number' }]}
                  keySelector={(option: AutocompleteOption) => option.value}
                  labelSelector={(option: AutocompleteOption) => option.label}
                />
              </Box>
              <Box mb={2}>
                <FormControl component="fieldset" fullWidth>
                  <Grid container>
                    <TextFieldLabel required>
                      {`Calling number to ${values.workflowAutomationActionTypeID === 'notify_lead_call' ? 'lead' : 'customer'}`}
                    </TextFieldLabel>
                    <Tooltip title="This number will be the number that appears on the leads phone. The caller remains as the assignee and you are unable to edit the caller. You can only edit the number that will appear on lead&apos;s phone here">
                      <InfoOutlinedIcon className={classes.infoIcon} />
                    </Tooltip>
                  </Grid>
                  <RadioGroup
                    name="callLeadAssigneeCaller"
                    value={values.callLeadAssigneeCaller === 'assignee' ? 'assignee' : ''}
                    onChange={(e) => {
                      const { name, value } = e.currentTarget;
                      setFieldValue(name, value);
                    }}
                  >
                    <FormControlLabel control={<Radio />} label="Display assignee's dialer number" value="assignee" />
                    <Grid container alignItems="center" wrap="nowrap" className={classes.customMobileSection}>
                      <Radio value="" />
                      <Field
                        as={AutocompleteInput}
                        required
                        fullWidth
                        filterSelectedOptions={false}
                        id="callLeadAssigneeCaller"
                        name="callLeadAssigneeCaller"
                        placeholder="Select business unit’s number"
                        className={clsx({
                          [classes.deletedValue]: (
                            values?.callLeadAssigneeCaller
                            && appendedListBusinessUnit
                              .find((item) => item.id === values.callLeadAssigneeCaller)?.disabled
                          ),
                        })}
                        disabled={values.callLeadAssigneeCaller === 'assignee'}
                        options={appendedListBusinessUnit}
                        keySelector={(option: CompanyBranchOption) => option.id}
                        labelSelector={(option: CompanyBranchOption) => option.name}
                        TextFieldProps={{
                          error: (
                            ((!values.workflowAutomationID && touched.callLeadAssigneeCaller)
                            || values.workflowAutomationID)
                            && !!errors.callLeadAssigneeCaller
                          ),
                          helperText: (
                            (
                              ((!values.workflowAutomationID && touched.callLeadAssigneeCaller)
                              || values.workflowAutomationID)
                              && errors.callLeadAssigneeCaller
                            ) ? errors.callLeadAssigneeCaller
                              : ''
                          ),
                        }}
                        onChange={(event: ChangeEvent<{}>, newValue: string) => {
                          setFieldValue('callLeadAssigneeCaller', newValue);
                        }}
                        getOptionDisabled={(option: CompanyBranchOption) => option.disabled}
                        filterOptions={
                          (options: CompanyBranchOption[]) => options
                            .filter((opt) => opt && opt.id)
                        }
                        renderOption={(option: CompanyBranchOption) => (
                          <div>
                            <Typography variant="subtitle2">
                              { option.name }
                            </Typography>
                            <Typography variant="caption" className={classes.phoneNumber}>
                              { option.msisdn || '(Phone number deleted)' }
                            </Typography>
                          </div>
                        )}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mb={1.5}>
                <FormControlLabel
                  control={(
                    <Switch
                      id="callLeadAssigneeRecord"
                      checked={!!values.callLeadAssigneeRecord}
                      value={!!values.callLeadAssigneeRecord}
                      onClick={(e) => {
                        const element = e.target as HTMLInputElement;
                        const { checked } = element;
                        setFieldValue('callLeadAssigneeRecord', checked);
                      }}
                    />
                  )}
                  label={(
                    <Typography variant="subtitle2">
                      Allow recording
                    </Typography>
                  )}
                  labelPlacement="end"
                />
              </Box>
            </>
          );
        default:
          return null;
      }
    }
    return null;
  };

  const renderBanner = (formik: FormikProps<AutomationFormAction>) => {
    const {
      values,
      errors,
    } = formik;
    if (values?.callLeadAssigneeCaller
      && values.callLeadAssigneeCaller !== 'assignee'
      && errors.callLeadAssigneeCaller) {
      let showroomWeb = '';
      let editBUURL = '';
      if (user?.company?.apps && user.company.apps.length > 0) {
        const srIndex = user.company.apps.findIndex((app) => app.appID === 'showroom');
        if (srIndex > -1 && user.company.apps[srIndex].webURL) {
          showroomWeb = user.company.apps[srIndex].webURL || '';
        }
        const selectedIndex = appendedListBusinessUnit
          .findIndex((bu) => bu.id === values.callLeadAssigneeCaller);
        if (selectedIndex > -1 && appendedListBusinessUnit[selectedIndex]?.disabled) {
          // If BU no longer exists
          editBUURL = `${showroomWeb}/business_units/`;
        } else {
          // If BU phone number got deleted
          editBUURL = `${showroomWeb}/business_units/${values.callLeadAssigneeCaller}`;
        }
      }
      return (
        <Box mb={1.5}>
          <Banner
            type="warning"
            content={(
              <>
                <Typography variant="subtitle2" display="inline">
                  The&nbsp;
                </Typography>
                <Typography
                  variant="subtitle2"
                  display="inline"
                  component={showroomWeb ? Link : Typography}
                  onClick={() => window.open(editBUURL, '_blank')}
                >
                  selected business unit
                </Typography>
                <Typography variant="subtitle2" display="inline">
                  ’s number is invalid or has been deleted.&nbsp;
                  Calling number to lead is now displaying assignee’s number instead.
                </Typography>
              </>
            )}
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <Formik
      initialValues={formikProps.values.actions[selectedActionIndex as number]}
      onSubmit={(values) => {
        formikProps.setFieldValue(
          `actions.${selectedActionIndex}`,
          values,
        );
        formikProps.setFieldValue(
          `actions.${selectedActionIndex}.workflowAutomationActionTypeID`,
          values.workflowAutomationActionTypeID,
        );
        onClose();
      }}
      validationSchema={() => validationSchema(listBusinessUnit || [])}
      validateOnMount
    >
      {(formik) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          isValid,
          isValidating,
          setFieldValue,
        } = formik;
        return (
          <Drawer title="Configure call" open={open} onClose={onClose}>
            <Box
              component={Form}
              p={2.5}
              height="100%"
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Box className={classes.container}>
                { renderBanner(formik) }
                <Box mb={1.5}>
                  <Field
                    as={AutocompleteInput}
                    required
                    label="Action type"
                    id="workflowAutomationActionTypeID"
                    name="workflowAutomationActionTypeID"
                    fullWidth
                    placeholder="Select"
                    filterOptions={
                      (options: AutocompleteOption[]) => getFilterOptions(
                        options,
                      )
                    }
                    options={getActionOptions()}
                    keySelector={(option: AutocompleteOption) => option.value}
                    labelSelector={(option: AutocompleteOption) => option.label}
                    TextFieldProps={{
                      error: (
                        touched.workflowAutomationActionTypeID
                        && !!errors.workflowAutomationActionTypeID
                      ),
                      helperText: <ErrorMessage name="workflowAutomationActionTypeID" />,
                    }}
                    onChange={(event: ChangeEvent<{}>, newValue: string) => {
                      setFieldValue('workflowAutomationActionTypeID', newValue);
                    }}
                  />
                </Box>
                {
                  values.workflowAutomationActionTypeID
                  && renderConfigurationFields(formik)
                }
              </Box>
              <Box className={classes.ctaButton}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isValidating || isSubmitting || !isValid}
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Drawer>
        );
      }}
    </Formik>
  );
};

export default ConfigureCallDrawer;
