import { API } from 'aws-amplify';

import { API_NAME_WORKFLOW } from '../constants';
import type { Workflow, WorkflowType } from '../types';

export interface ListWorkflowsInput {
  limit?: number;
  page?: number;
  appID?: WorkflowType['appID'];
  companyID?: string;
  isEnabled?: boolean;
  workflowTypeID?: Workflow['workflowTypeID'][];
  name?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  updatedAtFrom?: string;
  updatedAtTo?: string;
  hideGeneratedLeadWorkflows?: boolean;
}

export const listWorkflows = (input: ListWorkflowsInput) => API.get(API_NAME_WORKFLOW, '/workflows', { queryStringParameters: input });

export type CreateWorkflowInput = Pick<
Workflow,
| 'companyID'
| 'companyBranchID'
| 'workflowTypeID'
| 'name'
| 'description'
| 'startMethod'
| 'progressCalculationMethod'
| 'createdByAffiliateID'
>;

export const createWorkflow = (input: CreateWorkflowInput) => API.post(API_NAME_WORKFLOW, '/workflows', { body: input });

export const readWorkflow = (workflowID: string) => API.get(API_NAME_WORKFLOW, `/workflows/${workflowID}`, {});

export type UpdateWorkflowInput = Partial<
Pick<
Workflow,
| 'workflowTypeID'
| 'enabled'
| 'name'
| 'description'
| 'startMethod'
| 'progressCalculationMethod'
| 'updatedByAffiliateID'
>
>;

export const updateWorkflow = (
  workflowID: string,
  input: UpdateWorkflowInput,
) => API.put(API_NAME_WORKFLOW, `/workflows/${workflowID}`, { body: input });

interface WorkflowStageTaskRequest {
  id?: string;
  name: string;
  sequenceNo: number;
  workflowStageSubTasks?: WorkflowStageTaskRequest[];
}
interface WorkflowStageRequest {
  id?: string;
  name: string;
  sequenceNo: number;
  expectedCompletionDays?: number;
  accessGroups?: string[];
  workflowStageTasks?: WorkflowStageTaskRequest[];
}
export interface UpdateWorkflowStageInput {
  id: string;
  workflowStages: WorkflowStageRequest[];
}

export const updateWorkflowStage = (
  workflowID: string,
  input: UpdateWorkflowStageInput,
) => API.put(API_NAME_WORKFLOW, `/workflows/${workflowID}/stage-tasks`, { body: input });

export const deleteWorkflow = (workflowID: string) => API.del(API_NAME_WORKFLOW, `/workflows/${workflowID}`, {});
