import { API } from 'aws-amplify';

import { API_NAME_WORKFLOW, API_NAME_CREDIT_CONTROL } from '../constants';
import type { WorkflowCase, WorkflowType } from '../types';

export interface ListWorkflowCasesInput {
  limit?: number;
  page?: number;
  companyID?: string;
  status?: WorkflowCase['status'];
  workflowID?: string;
  workflowTypeID?: WorkflowCase['workflowTypeID'][];
  workflowTypeAppID?: WorkflowType['appID'][];
  assigneeID?: string[];
  name?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  updatedAtFrom?: string;
  updatedAtTo?: string;
  projectID?: string;
  projectUnitID?: string;
}

export const listWorkflowCases = (input: ListWorkflowCasesInput) => API.get(API_NAME_WORKFLOW, '/workflowcases', {
  queryStringParameters: input,
});

export const readWorkflowCase = (workflowCaseID: string) => API.get(API_NAME_WORKFLOW, `/workflowcases/${workflowCaseID}`, {});

export interface PsTrackingWorkflowCasePayload {
  workflowTypeID: WorkflowCase['workflowTypeID'];
  workflowID: string;
  projectID: string;
  projectUnitIDs: string[];
}

export interface PsTrackingWorkflowCaseHeader {
  'x-company-id': string;
}

export interface CreateWorkflowCaseInput {
  appID: WorkflowType['appID'];
  workflowTypeID: WorkflowCase['workflowTypeID'];
  workflowID: string;
  pathAction: 'assign';
  customHeaders: | PsTrackingWorkflowCaseHeader;
  payload: | PsTrackingWorkflowCasePayload;
}

export const createWorkflowCase = ({
  appID,
  workflowTypeID,
  workflowID,
  pathAction,
  customHeaders,
  payload,
}: CreateWorkflowCaseInput) => {
  let apiName = '';
  if (appID === 'credit_control') {
    apiName = API_NAME_CREDIT_CONTROL;
  }

  const path = `/workflowcase/${workflowTypeID}/${workflowID}/${pathAction}`;
  return API.post(apiName, path, {
    headers: { ...customHeaders },
    body: { ...payload },
  });
};

export type UpdateWorkflowCaseInput = Partial<
Pick<
WorkflowCase,
| 'name'
| 'description'
| 'status'
| 'assigneeIDs'
| 'workflowID'
>
>;

export const updateWorkflowCase = (
  workflowCaseID: string,
  input: UpdateWorkflowCaseInput,
) => API.put(API_NAME_WORKFLOW, `/workflowcases/${workflowCaseID}`, {
  body: input,
});

export const deleteWorkflowCase = (workflowCaseID: string) => API.del(API_NAME_WORKFLOW, `/workflowcases/${workflowCaseID}`, {});
