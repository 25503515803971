import { ContentBlock } from 'draft-js';
import { escapeRegExp, findIndex } from 'lodash';
import { TagProps } from './types';
import { customTagOptions } from '../constants';
import { AutocompleteOption } from '../../AutocompleteInput/types';
import useStyles from './styles';

const tagStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
) => {
  const text = contentBlock.getText();
  customTagOptions.forEach((tag) => {
    const matches = [...text.matchAll(new RegExp(escapeRegExp(tag.value), 'g'))];
    matches.forEach((match) => {
      if (match.index !== undefined) {
        callback(match.index, match.index + match[0].length);
      }
    });
  });
};

const getTagLabel = (text: string) => {
  let label = text;

  if (text) {
    const index = findIndex(customTagOptions, (tag: AutocompleteOption) => (
      tag.value === text
    ));
    if (index > -1) {
      label = customTagOptions[index].label;
    }
  }
  return label;
};

const Tag = (decoratedText: string, disabled: boolean) => {
  const classes = useStyles();
  return (
    <span
      contentEditable={false}
      className={`${classes.variableTag} ${disabled ? 'disabled' : ''}`}
    >
      { getTagLabel(decoratedText) }
    </span>
  );
};

const TagDecorator = (disabled: boolean) => ({
  strategy: tagStrategy,
  component: ({ decoratedText }: TagProps) => Tag(decoratedText, disabled),
});

export default TagDecorator;
