import {
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { AutocompleteInput } from '..';
import { customTagOptions } from '../ContentEditor/constants';
import { AutocompleteOption } from '../AutocompleteInput/types';
import { PersonaliseModalProps } from './types';
import useStyles from './styles';

const PersonaliseModal = ({
  open,
  onClose,
  onSubmit,
}: PersonaliseModalProps) => {
  const classes = useStyles();

  const [variable, setVariable] = useState('');

  useEffect(() => {
    if (!open) {
      setVariable('');
    }
  }, [open]);

  const handleChange = (
    e: ChangeEvent<{}>,
    value: any,
  ) => {
    setVariable(value);
  };

  const handleSubmit = () => {
    onSubmit(variable);
  };

  return (
    <Dialog open={open} className={classes.dialogContainer}>
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Custom tag</Typography>
          <IconButton
            edge="end"
            onClick={onClose}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="textSecondary" variant="body2">
          Add a custom tag to personalise your email
          by selecting from available field values as below.
        </DialogContentText>
        <Box>
          <AutocompleteInput
            required
            label="Variable"
            placeholder="Search"
            options={customTagOptions}
            value={variable}
            onChange={handleChange}
            keySelector={(option: AutocompleteOption) => option.value}
            labelSelector={(option: AutocompleteOption) => option.label}
          />
        </Box>
        <DialogContentText variant="caption" className={classes.dialogCaption}>
          Note: The character counter assumes a length of 20 characters for each dynamic content.
          If the length of the personalised content selected is longer than 20 characters,
          it will be cut off.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box m={1.5} mr={0} pr={1.5}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!variable}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PersonaliseModal;
