import { Grid } from '@material-ui/core';
import {
  InfoOutlined as InfoOutlinedIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { BannerProps } from './types';
import useStyles from './styles';

const Banner = ({
  type,
  content,
}: BannerProps) => {
  const classes = useStyles();

  const renderIcon = () => {
    switch (type) {
      case 'info':
        return <InfoOutlinedIcon />;
      case 'warning':
        return <WarningIcon />;
      default:
        break;
    }
    return null;
  };

  return (
    <Grid
      container
      alignItems="center"
      wrap="nowrap"
      className={clsx(
        classes.container,
        type,
      )}
    >
      <Grid item>
        { renderIcon() }
      </Grid>
      <Grid item>
        { content }
      </Grid>
    </Grid>
  );
};

export default Banner;
