import Bugsnag from '@bugsnag/js';
import {
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getUserRole } from '../constants';

export interface App {
  appID: string;
  webURL?: string;
  hasCallAccess?: boolean;
}

interface CompanyData {
  apps?: App[];
}
interface User {
  id: string,
  name: string,
  groups: string[],
  role: string,
  companyID: string,
  companyBranchID: string,
  company: CompanyData,
}

interface AuthContext {
  authenticated: boolean;
  loading: boolean;
  user: User;

  /**
   * Authenticate user's Cognito session.
   * Returns the status of authentication. Auto redirects to
   * access denied screen when not authenticated - `redirect` defaults to `true`.
   */
  authenticate(): Promise<boolean>;
}

const authContext = createContext<AuthContext>({
  authenticated: false,
  loading: true,
  user: {
    id: '',
    name: '',
    groups: [],
    role: '',
    companyID: '',
    companyBranchID: '',
    company: {
      apps: [],
    },
  },
  authenticate: () => Promise.resolve(false),
});

export function useAuthProvider(): AuthContext {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    id: '',
    name: '',
    groups: [],
    role: '',
    companyID: '',
    companyBranchID: '',
    company: {
      apps: [],
    },
  });

  useEffect(() => {
    const getMsgFromParent = (event: any) => {
      let data: CompanyData = {
        apps: [],
      };

      if (event?.data?.apps) {
        data = {
          ...data,
          apps: event.data.apps,
        } as CompanyData;
      }

      setUser((prev) => ({
        ...prev,
        company: {
          ...data,
        },
      } as any));
    };

    if (authenticated && window.addEventListener) {
      window.addEventListener('message', getMsgFromParent, false);
    }

    return () => {
      window.removeEventListener('message', getMsgFromParent);
    };
  }, [authenticated]);

  const authenticate = async (): Promise<boolean> => {
    setLoading(true);
    let session: CognitoUserSession | null = null;
    try {
      const authUser: CognitoUser = await Auth.currentAuthenticatedUser();
      session = authUser.getSignInUserSession();
    } catch (err) {
      setAuthenticated(false);
      setLoading(false);
      return false;
    }

    if (session && session.isValid()) {
      const payload = session.getIdToken().decodePayload();
      const {
        sub,
        name,
        email,
        'cognito:groups': groups,
        'custom:company_id': companyID,
        'custom:company_branch_id': companyBranchID,
      } = payload;

      const role = getUserRole(groups || []);
      // Get user's psql id
      setUser((prev) => ({
        ...prev,
        id: sub || '',
        name: name || '',
        groups,
        role,
        companyID: companyID || '',
        companyBranchID: companyBranchID || '',
      }));
      Bugsnag.addMetadata('user', {
        user_id: sub,
        name,
        email,
        company_id: companyID,
      });
      setAuthenticated(true);
      setLoading(false);
      return true;
    }

    setAuthenticated(false);
    setLoading(false);
    return false;
  };

  return {
    authenticated,
    loading,
    user,
    authenticate,
  };
}

export const useAuth = () => useContext(authContext);

interface AuthProviderProps {
  children: ReactNode,
}

export function AuthProvider({ children }: AuthProviderProps) {
  const auth = useAuthProvider();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}
