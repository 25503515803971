import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify, Auth } from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import reportWebVitals from './reportWebVitals';
import amplifyConfig from './config/amplifyConfig';
import config from './config/config';
import { AuthProvider, ProvideAlert } from './hooks';
import globalTheme from './styles/globalTheme';
import App from './pages/App';
import 'flag-icons/css/flag-icons.min.css';

import './index.css';

Amplify.configure(amplifyConfig);
Bugsnag.start({
  apiKey: '050f53ac0f2cefa1a9684fd9eda996f9',
  releaseStage: config.app.stage,
  plugins: [new BugsnagPluginReact(React)],
});

const httpLink = (endpoint: string) => createHttpLink({
  uri: endpoint,
});

const authMiddleware = setContext(async (_, { headers }) => {
  const token = await Auth.currentSession();
  return {
    headers: {
      ...headers,
      authorization: token.getIdToken().getJwtToken() || null,
    },
  };
});

const generateClient = (endpoint: string) => new ApolloClient({
  link: authMiddleware.concat(httpLink(endpoint)),
  cache: new InMemoryCache(),
});

export const defaultClient = generateClient('graphql');
export const clientLeadCampaign = generateClient(config.api.leadCampaignApiUrl || '');
export const clientLeadForm = generateClient(config.api.leadFormApiUrl || '');
export const clientCreditControl = generateClient(config.api.creditControlApiUrl || '');
export const clientLeadUsers = generateClient(config.api.leadUsersApiUrl || '');
export const clientLeadCall = generateClient(config.api.leadCallApiUrl || '');

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

class LocalizedUtils extends MomentUtils {
  getWeekdays() {
    return [0, 1, 2, 3, 4, 5, 6].map((dayOfWeek) => this.moment().weekday(dayOfWeek).format('dd')[0]);
  }
}

// FIXME: StrictMode currently will throw "findDOMNode is deprecated" error
// for some material-ui components and will only be fixed in v5
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={globalTheme}>
        <ProvideAlert>
          <AuthProvider>
            <ApolloProvider client={defaultClient}>
              <MuiPickersUtilsProvider utils={LocalizedUtils}>
                <CssBaseline />
                <Router>
                  <App />
                </Router>
              </MuiPickersUtilsProvider>
            </ApolloProvider>
          </AuthProvider>
        </ProvideAlert>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
