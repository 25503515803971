import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  fontColors,
  fontSize,
  tertiaryColors,
} from '../../styles/variables';

export default makeStyles((theme) => (({
  asterik: {
    color: 'red',
  },
  container: {
    paddingBottom: '5rem',
  },
  ctaButton: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    width: '350px',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  infoIcon: {
    fontSize: fontSize['15px'],
    color: fontColors.body,
    marginLeft: theme.spacing(1),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltipList: {
    padding: '0 0 0 1rem',
  },
  remarkTitle: {
    backgroundColor: tertiaryColors.pastelBlue,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    padding: '0.7rem 1rem',
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      width: '1.2rem',
      height: '1.2rem',
      marginRight: '0.5rem',
    },
    '& > h6': {
      lineHeight: 'unset',
    },
  },
  remarkBody: {
    backgroundColor: tertiaryColors.lightBlue,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    padding: '1rem',
  },
  remarkList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    color: fontColors.body,
    '& > li': {
      display: 'flex',
      alignItems: 'flex-start',
    },
    '& li + li': {
      marginBottom: '0.5rem',
    },
    '& > li::before': {
      content: '"•"',
      display: 'inline-block',
      width: '5px',
      marginRight: '0.5rem',
    },
  },
  switchLabel: {
    marginLeft: 0,
    marginRight: 0,
    '& .MuiSwitch-root': {
      marginLeft: 0,
    },
  },
})));
