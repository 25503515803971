import { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from 'formik';
import { object, string } from 'yup';
import {
  AutocompleteInput,
  Drawer,
  TextFieldInput,
  TextFieldLabel,
} from '..';
import { ConfigureMergeDuplicateDrawerProps } from './types';
import {
  AutomationFormAction,
  AutomationFormValues,
  TParams,
} from '../../types';
import { AutocompleteOption } from '../AutocompleteInput/types';
import { leadActions } from '../../pages/Workflow/Tabs/LeadAutomation/formData';
import { LightBulbIcon } from '../../assets/icons';
import useStyles from './styles';

const ConfigureMergeDuplicationDrawer = ({
  actions,
  open,
  onClose,
  selectedActionIndex,
  selectedSuggestion,
}: ConfigureMergeDuplicateDrawerProps) => {
  const classes = useStyles();

  const validationSchema = object({
    workflowAutomationActionTypeID: string().required('Action type is required'),
  });

  const formikProps = useFormikContext<AutomationFormValues>();

  const { type } = useParams<TParams>();

  const leadAssignmentOpts: AutocompleteOption[] = [
    { label: 'Default: Auto assignment', value: 'default' },
    { label: 'Assign to latest available assignee based on opportunity', value: 'latest' },
    { label: 'Assign to earliest available assignee based on opportunity', value: 'earliest' },
  ];

  const getActionOptions = () => {
    let options: AutocompleteOption[] = [];
    if (type === 'lead') {
      if (selectedSuggestion) {
        const suggestionIndex = leadActions.findIndex((action) => (
          action.name === selectedSuggestion
        ));
        if (suggestionIndex > -1) {
          options = leadActions[suggestionIndex].types;
        }
      } else {
        options = [];
      }
    }
    return options;
  };

  const renderConfigurationFields = (formik: FormikProps<AutomationFormAction>) => {
    const {
      values,
      setFieldValue,
    } = formik;
    switch (values.workflowAutomationActionTypeID) {
      case 'merge_duplicate_leads':
        return (
          <>
            <Box mb={1.5}>
              <TextFieldInput
                label="Trigger merge"
                variant="outlined"
                fullWidth
                disabled
                required
                value={values?.triggerMergeOn === '* * * * *' ? 'Immediate' : values.triggerMergeOn}
              />
            </Box>
            <Box mb={3}>
              <FormControl component="fieldset" fullWidth>
                <Grid container>
                  <TextFieldLabel required>
                    Choose the master record
                  </TextFieldLabel>
                  <Tooltip
                    title={(
                      <ul className={classes.tooltipList}>
                        <li>
                          Incoming record - Merge into previous newest lead while retaining previous
                          record&apos;s info
                        </li>
                        <li>
                          Latest record - Merge and overwrite previous record with latest info
                        </li>
                      </ul>
                    )}
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </Tooltip>
                </Grid>
                <RadioGroup
                  className={classes.radioGroup}
                  name="mergeLeadMasterRecord"
                  value={values.mergeLeadMasterRecord}
                  onChange={(e) => {
                    const { name, value } = e.currentTarget;
                    setFieldValue(name, value);
                  }}
                >
                  <FormControlLabel control={<Radio />} label="Previous record" value="previous" />
                  <FormControlLabel control={<Radio />} label="Incoming record" value="incoming" />
                </RadioGroup>
              </FormControl>
            </Box>
          </>
        );
      case 'merge_duplicate_leads_to_customer':
        return (
          <>
            <Box mb={1.5}>
              <TextFieldInput
                label="Trigger merge"
                variant="outlined"
                fullWidth
                disabled
                required
                value={values?.triggerMergeOn === '* * * * *' ? 'Immediate' : values.triggerMergeOn}
              />
            </Box>
            <Box mb={1.5}>
              <FormControl component="fieldset" fullWidth>
                <Grid container>
                  <TextFieldLabel required>
                    Do you want to bypass required fields?
                  </TextFieldLabel>
                  <Tooltip
                    title={(
                      <>
                        <p>
                          Selecting &apos;yes&apos; will skip the verification and
                          merge the lead profile directly with the customer profile.
                        </p>
                        <p>
                          Selecting &apos;no&apos; will enforce the verification and
                          any differences in the profiles will prevent the merging of
                          the lead profile and customer profile.
                        </p>
                      </>
                    )}
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </Tooltip>
                </Grid>
                <RadioGroup
                  className={classes.radioGroup}
                  name="mergeLeadBypassRequired"
                  value={
                    typeof values?.mergeLeadBypassRequired === 'boolean'
                      ? values.mergeLeadBypassRequired.toString()
                      : null
                  }
                  onChange={(e) => {
                    const { name, value } = e.currentTarget;
                    setFieldValue(name, String(value).toLowerCase() === 'true');
                  }}
                >
                  <FormControlLabel control={<Radio />} label="Yes" value="true" />
                  <FormControlLabel control={<Radio />} label="No" value="false" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mb={values.leadAssignment !== 'default' ? 1.5 : 3}>
              <Field
                as={AutocompleteInput}
                required
                label="Lead Assignment"
                name="leadAssignment"
                fullWidth
                placeholder="Select"
                options={leadAssignmentOpts}
                keySelector={(option: AutocompleteOption) => option.value}
                labelSelector={(option: AutocompleteOption) => option.label}
                onChange={(event: ChangeEvent<{}>, newValue: string) => {
                  setFieldValue('leadAssignment', newValue);
                }}
              />
            </Box>
            {
              values.leadAssignment !== 'default' && (
                <Box mb={3}>
                  <Typography variant="subtitle2">
                    Action for existing customer without assignee
                    <span className={classes.asterik}> *</span>
                  </Typography>
                  <FormControlLabel
                    className={classes.switchLabel}
                    control={(
                      <Switch
                        id="leadAllowEmptyAssignee"
                        checked={!!values.leadAllowEmptyAssignee}
                        value={!!values.leadAllowEmptyAssignee}
                        onClick={(e) => {
                          const element = e.target as HTMLInputElement;
                          const { checked } = element;
                          setFieldValue('leadAllowEmptyAssignee', checked);
                        }}
                      />
                    )}
                    label={values.leadAllowEmptyAssignee ? 'Create new opportunity without assignee' : 'Do not merge lead into existing customer'}
                    labelPlacement="end"
                  />
                </Box>
              )
            }
          </>
        );
      default:
        return null;
    }
  };

  const getFilterOptions = (
    options: AutocompleteOption[],
  ) => {
    // Filter out options that selected by own and other action fields
    let filteredOptions = options;
    if (actions?.length) {
      const selectedOptions: string[] = [];
      actions.forEach((action, index) => {
        if (index !== selectedActionIndex && action.workflowAutomationActionTypeID) {
          selectedOptions.push(action.workflowAutomationActionTypeID);
        }
      });
      filteredOptions = options.filter((option) => !selectedOptions.includes(option.value));
    }
    return filteredOptions;
  };

  return (
    <Formik
      initialValues={formikProps.values.actions[selectedActionIndex as number]}
      onSubmit={(values) => {
        formikProps.setFieldValue(
          `actions.${selectedActionIndex}`,
          values,
        );
        formikProps.setFieldValue(
          `actions.${selectedActionIndex}.workflowAutomationActionTypeID`,
          values.workflowAutomationActionTypeID,
        );
        onClose();
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formik) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          isValidating,
          isSubmitting,
          isValid,
        } = formik;
        return (
          <>
            <Drawer title="Configure action" open={open} onClose={onClose}>
              <Box
                component={Form}
                p={2.5}
                height="100%"
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Box className={classes.container}>
                  <Box mb={1.5}>
                    <Field
                      as={AutocompleteInput}
                      required
                      label="Action type"
                      id="workflowAutomationActionTypeID"
                      name="workflowAutomationActionTypeID"
                      fullWidth
                      placeholder="Select"
                      filterOptions={
                        (options: AutocompleteOption[]) => getFilterOptions(
                          options,
                        )
                      }
                      options={getActionOptions()}
                      keySelector={(option: AutocompleteOption) => option.value}
                      labelSelector={(option: AutocompleteOption) => option.label}
                      TextFieldProps={{
                        error: (
                          touched.workflowAutomationActionTypeID
                          && !!errors.workflowAutomationActionTypeID
                        ),
                        helperText: <ErrorMessage name="workflowAutomationActionTypeID" />,
                      }}
                      onChange={(event: ChangeEvent<{}>, newValue: string) => {
                        // Remove unrelevant field values
                        if (values.workflowAutomationActionTypeID === 'merge_duplicate_leads'
                          && (!newValue || newValue !== 'merge_duplicate_leads')) {
                          setFieldValue('mergeLeadMasterRecord', null);
                        } else if (values.workflowAutomationActionTypeID === 'merge_duplicate_leads_to_customer'
                        && (!newValue || newValue !== 'merge_duplicate_leads_to_customer')) {
                          setFieldValue('mergeLeadBypassRequired', null);
                        }

                        setFieldValue('workflowAutomationActionTypeID', newValue);

                        // This is to set the default data for the action type
                        if (newValue === 'merge_duplicate_leads') {
                          setFieldValue('mergeLeadMasterRecord', 'previous');
                        } else if (newValue === 'merge_duplicate_leads_to_customer') {
                          setFieldValue('mergeLeadBypassRequired', true);
                        }
                      }}
                    />
                  </Box>

                  {/* Information Bubble Render */}
                  {
  (values.workflowAutomationActionTypeID === 'merge_duplicate_leads'
  || values.workflowAutomationActionTypeID === 'merge_duplicate_leads_to_customer')
  && (
    <>
      {renderConfigurationFields(formik)}
      <Box mb={1.5}>
        <Box className={classes.remarkTitle}>
          <img alt="automation action" src={LightBulbIcon} />
          <Typography variant="subtitle2">
            {values.workflowAutomationActionTypeID === 'merge_duplicate_leads'
              ? 'Assignee for duplicated leads'
              : 'Merging leads with opportunities to existing customer'}
          </Typography>
        </Box>
        <Box className={classes.remarkBody}>
          <ul className={classes.remarkList}>
            {values.workflowAutomationActionTypeID === 'merge_duplicate_leads' && (
              <>
                <li>
                  <Typography variant="body2">
                    This workflow will run before Auto assignment
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    This workflow will merge leads, then assign to previous assignee if available
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    If previous assignee’s account is deactivated,
                    it will assign merged lead via auto-assignment
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    The workflow will not merge leads if the previous lead’s
                    latest opportunity has no assignee.
                    In this case, the lead will be assigned via auto-assignment
                  </Typography>
                </li>
              </>
            )}
            {values.workflowAutomationActionTypeID === 'merge_duplicate_leads_to_customer' && (
              <>
                <li>
                  <Typography variant="body2">
                    This workflow will run before Auto assignment,
                    and will only merge leads with opportunity to existing customers
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    The lead with opportunities will be routed based on
                    Auto Assignment rule if it’s present, and will remain without assignee
                    if there’s no existing Auto Assignment rule
                  </Typography>
                </li>
              </>
            )}
          </ul>
        </Box>
      </Box>
    </>
  )
}

                </Box>
                <Box className={classes.ctaButton}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isValidating || isSubmitting || !isValid}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Drawer>
          </>
        );
      }}
    </Formik>
  );
};

export default ConfigureMergeDuplicationDrawer;
