import {
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { LoadingContentOverlayProps } from './types';
import useStyles from './styles';

const LoadingContentOverlay = (
  props: LoadingContentOverlayProps,
) => {
  const {
    isLoading,
    fullHeight,
  } = props;
  const classes = useStyles();

  if (isLoading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={`${classes.loading} ${fullHeight ? classes.fullHeight : ''}`}
      >
        <CircularProgress />
      </Grid>
    );
  }
  return null;
};

LoadingContentOverlay.defaultProps = {
  fullHeight: false,
};

export default LoadingContentOverlay;
