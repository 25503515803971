export const primaryColors = {
  main: '#49b3b1',
  dark: '#2c8483',
  light: '#32c8c5',
  contrastText: '#ffffff',
};

export const secondaryColors = {
  main: '#0094b3',
  dark: '#016e8d',
  light: '#00bbe0',
  contrastText: '#ffffff',
};

export const tertiaryColors = {
  grey: '#dbddde',
  greyOne: '#969ca4',
  dimGrey: '#99a7b0',
  lightGrey: '#f8f9f9',
  lighterGrey: '#586976',
  mediumGrey: '#f4f5f7',
  darkGrey: '#e7eaed',
  whiteSmoke: '#f4f4f4',
  stone: '#9caac1',

  cyprus: '#06363c',

  dimGreen: '#e5faf2',
  green: '#5bc699',
  lightGreen: '#ecf7ee',
  darkGreen: '#50b793',

  blue: '#3da0e4',
  lightBlue: '#edf7fd',
  pastelBlue: '#d9edfa',

  red: '#ee0000',
  alertRed: '#fc7a71',
  alertYellow: '#fdc74c',

  mustard: '#fff6d1',
  amber: '#f2b42a',
  marigold: '#ffcb00',

  lightPink: '#ffeded',
  peach: '#ff796e',
  lightOrange: '#fff6d1',
  orange: '#f79a3e',

  lavender: '#7282D7',
};

export const fontColors = {
  header: '#00233b',
  body: '#596975',
  subBody: '#969ca4',
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const fontFamily = {
  openSans: '"Open Sans", serif',
  montserrat: '"Montserrat", serif',
};

export const fontSize = {
  '10px': '0.625rem',
  '12px': '0.75rem',
  '14px': '0.875rem',
  '15px': '0.9375rem',
  '16px': '1rem',
  '17px': '1.0625rem',
  '18px': '1.125rem',
  '19px': '1.1875rem',
  '20px': '1.25rem',
  '21px': '1.3125rem',
  '22px': '1.375rem',
  '24px': '1.5rem',
  '32px': '2.5rem',
};

export const fontVariants = {
  h1: {
    fontFamily: fontFamily.montserrat,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize['24px'],
  },
  h2: {
    fontFamily: fontFamily.montserrat,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize['22px'],
  },
  h3: {
    fontFamily: fontFamily.montserrat,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize['21px'],
  },
  h4: {
    fontFamily: fontFamily.montserrat,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize['19px'],
  },
  h5: {
    fontFamily: fontFamily.montserrat,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize['17px'],
  },
  h6: {
    fontFamily: fontFamily.montserrat,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize['15px'],
  },
  body1: {
    fontFamily: fontFamily.openSans,
    fontWeight: fontWeight.regular,
    fontSize: fontSize['14px'],
  },
  body2: {
    fontFamily: fontFamily.openSans,
    fontWeight: fontWeight.regular,
    fontSize: fontSize['12px'],
  },
  subtitle1: {
    fontFamily: fontFamily.openSans,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize['14px'],
  },
  subtitle2: {
    fontFamily: fontFamily.openSans,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize['12px'],
  },
  caption: {
    fontFamily: fontFamily.openSans,
    fontWeight: fontWeight.regular,
    fontSize: fontSize['10px'],
  },
  overline: {
    fontFamily: fontFamily.openSans,
    fontWeight: fontWeight.bold,
    fontSize: fontSize['10px'],
  },
};
