import { Tab as MuiTab, Typography } from '@material-ui/core';
import { TabProps } from './types';
import useStyles from './styles';

const Tab = ({ children, subLabel, ...MuiTabProps }: TabProps) => {
  const classes = useStyles();
  return (
    <div>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        className={classes.subLabel}
      >
        {subLabel}
      </Typography>
      <MuiTab
        classes={{
          root: classes.root,
          textColorPrimary: classes.textColorPrimary,
        }}
        {...MuiTabProps}
      >
        {children}
      </MuiTab>
    </div>
  );
};

export default Tab;
