import { makeStyles } from '@material-ui/core/styles';
import { tertiaryColors } from '../../styles/variables';

export default makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-input': {
      maxHeight: '2.5rem',
    },
  },
  adornment: {
    color: tertiaryColors.grey,
  },
  searchIcon: {
    height: '1.5rem',
    width: '1.5rem',
    filter: 'invert(40%) sepia(5%) saturate(1448%) hue-rotate(163deg) brightness(94%) contrast(85%)',
  },
  clearIcon: {
    width: '1rem',
    height: '1rem',
  },
}));
