import { CompositeDecorator } from 'draft-js';

import tagDecorator from './VariableTag';

const Decorator = (disabled: boolean) => (
  new CompositeDecorator([
    tagDecorator(disabled),
  ])
);

export default Decorator;
