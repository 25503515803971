import { parseErrorMessage } from './parse';

export const getErrorMessage = (err: any, errorFieldName?: string) => {
  const errorCode = err?.response?.status;
  const errorMsgFromRes = err?.response?.data?.error_message ?? err?.response?.data?.message;
  let errorMsg = '';

  // if is backend / server error: return default message
  if (errorCode >= 500) {
    errorMsg = err?.message;
  }

  // if is front end 400 error: display the correct message
  if (errorMsgFromRes) {
    errorMsg = parseErrorMessage(errorMsgFromRes, errorFieldName);
  }

  // if there is no error message
  if (!errorMsg) {
    errorMsg = 'An error occurred';
  }

  return errorMsg;
};
