import {
  makeStyles,
} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  required: {
    '&::after': {
      content: '" * "',
      color: theme.palette.error.main,
    },
  },
}));
