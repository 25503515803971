import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  fontSize,
  fontFamily,
  tertiaryColors,
  fontWeight,
} from '../../styles/variables';

export default makeStyles((theme) => ({
  chip: {
    backgroundColor: tertiaryColors.mediumGrey,
    margin: theme.spacing(0.2),
  },
  chipDeletable: {
    width: '18px',
  },
  chipLabel: {
    ...theme.typography.body2,
  },
  listbox: {
    padding: 0,
    margin: 0,
  },
  option: {
    ...theme.typography.body1,
    wordBreak: 'break-all',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    boxShadow: 'unset',
    border: `1px solid ${tertiaryColors.grey}`,
    margin: 0,
  },
  groupLabel: {
    top: '0',
    lineHeight: 'unset',
    paddingTop: theme.spacing(1.375),
    paddingBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontFamily: fontFamily.openSans,
    fontWeight: fontWeight.bold,
    fontSize: fontSize['12px'],
  },
  groupUl: {
    paddingBottom: theme.spacing(1),
  },
  endAdornment: {
    top: 'unset',
  },
  root: {
    '& input': {
      height: '22px',
    },
  },
}));
