import { makeStyles } from '@material-ui/core/styles';
import { fontColors, tertiaryColors } from '../../styles/variables';

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: '1rem',
    [theme.breakpoints.up('sm')]: {
      padding: '1rem 1.5rem',
    },
  },
  section: {
    width: '100%',
    padding: '1.68rem 0',
    '&:first-child': {
      paddingTop: 0,
      '& > h6: first-child': {
        marginBottom: '1.5rem',
      },
      '& > div:not(:first-child) > h6': {
        marginTop: '1rem',
      },
    },
    '&:not(:first-child)': {
      borderTop: `1px solid ${tertiaryColors.mediumGrey}`,
    },
    '& > h6:not(:first-child):not(:nth-child(2))': {
      marginTop: '0.86rem',
    },
    '& > h6:not(:first-child)': {
      marginTop: '0.5rem',
      marginBottom: '4px',
    },
  },
  footer: {
    padding: '1rem',
    minHeight: '80px',
  },
  secondaryButtons: {
    '& > button': {
      padding: '0 1rem',
      color: fontColors.subBody,
      textTransform: 'inherit',
    },
    '& > button:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  primaryButtons: {
    '& button + button': {
      marginLeft: '1rem',
    },
  },
}));
